import { Link } from 'react-router-dom';
import { HelElement, HelFC } from '../../interfaces';
import { StoryCardProps } from './interfaces';

export const StoryCard: HelFC<StoryCardProps> = ({
  item: {
    urls, name = '', description = '', id,
  }, className, onClick, index,
}): HelElement => (
  <>
    {!!id
   && (
   <div role="presentation" className={`story-card ${className || ''}`} onClick={onClick} tabIndex={index}>

     <Link to={`/stories/${id}`}>
       {!!urls?.original && <div className="overlay" />}
       <div className="image">
         {urls?.original ? <img src={urls?.original} alt="story 1" />
           : <div className="skeleton" />}
       </div>
       <div className="story-info">
         <h4>{name}</h4>
         <span>{description}</span>
       </div>
     </Link>
   </div>
   )}
  </>
);
