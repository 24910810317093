import { HelElement, HelFC } from '../../interfaces';
import { PoolCardProps } from './interfaces';
import { PoolDetailBlock, ProgressBlock, IconArrowSmallRight } from '../../components';
import { PoolLink } from '../../components/PoolLink';
import { useMediaQuery } from '../../utils';

export const PoolCard: HelFC<PoolCardProps> = ({
  id,
  title,
  description,
  image,
  className,
  details,
  closed = false,
}): HelElement => {
  const isCardView = useMediaQuery('(max-width: 1200px)');
  const progressData = details.find(({ currentValue }) => (currentValue || currentValue === 0));

  return (
    <div className={`pool-card ${className || ''}`}>
      <div className="pool-card-image">
        {image && <img src={image} alt="pool 1" />}
      </div>
      {closed && (
      <span className="closed-badge">
        Closed
      </span>
      )}
      <div className="pool-info">
        <div className="title">
          <h3>{title}</h3>
          <span className="description">{description}</span>
        </div>
        <div className={`pool-details ${className || ''}`}>
          {
          details.map(({
            value, currentValue, label, suffix, prefix,
          }, index) => {
            if (currentValue || currentValue === 0) {
              if (!isCardView) {
                return (
                  <div key={index}>
                    <ProgressBlock
                      value={value}
                      currentValue={currentValue}
                      label={label}
                      suffix={suffix}
                      prefix={prefix}
                    />
                  </div>
                );
              }
              return <></>;
            }
            return <div key={index}><PoolDetailBlock value={value} label={label as string} prefix={prefix} suffix={suffix} /></div>;
          })
        }
        </div>
        <div className="view-projects">
          {isCardView && progressData
            && (
            <ProgressBlock
              value={progressData.value}
              currentValue={progressData.currentValue}
              label={progressData.label}
              suffix={progressData.suffix}
            />
            )}
          <PoolLink id={id as string}>
            More Info
            <IconArrowSmallRight />
          </PoolLink>
        </div>
      </div>
    </div>
  );
};
