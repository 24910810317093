import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import {
  Home,
  Story,
} from '../../../pages';
import { HelFC } from '../../../interfaces';

export const AppRoutes:HelFC = () => (
  <Routes>
    <Route path="/home" element={<Home />} />
    <Route path="/stories/:id" element={<Story />} />
    <Route path="*" element={<Navigate to="/home" />} />
  </Routes>
);
