import {
  PlatformActionType,
  PlatformInterface,
} from './interfaces';

import { PlatformActionTypes } from './actions';
import { UseReducerType } from '../../../interfaces';
import { getLocalPlatform } from './localStore';
import { LocalConfig } from '../../../../services';
import { ConfigItemNameTypes } from '../../../../constants';
import { ItemType } from '../../../../interfaces/dataListInterfaces/DataParamsInterface';

export const platformStateReducer: UseReducerType<PlatformInterface, PlatformActionType> = (state, action)
:PlatformInterface => {
  switch (action.type) {
    case PlatformActionTypes.GetPlatformLocal:
      return {
        ...getLocalPlatform(),
      };

    case PlatformActionTypes.SetPlatform:
      LocalConfig.setItem(ConfigItemNameTypes.Platform, action.payload as ItemType);
      return action.payload;

    default:
      return state;
  }
};
