import { HelElement, HelFC } from '../../interfaces';
import { IconPropsInterface } from './interfaces';

export const IconDown: HelFC<IconPropsInterface> = ({ width, height, className }): HelElement => (
  <svg
    className={`icon-svg ${className || 'icon-primary'}`}
    id="svg-icon-discord"
    width={width}
    height={height}
    viewBox="0 0 16 107"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7.29289 106.365C7.68341 106.756 8.31658 106.756 8.7071 106.365L15.0711 100.001C15.4616 99.6107 15.4616 98.9775 15.0711 98.587C14.6805 98.1965 14.0474 98.1965 13.6569 98.587L8 104.244L2.34314 98.587C1.95262 98.1965 1.31945 98.1965 0.928928 98.587C0.538404 98.9775 0.538404 99.6107 0.928928 100.001L7.29289 106.365ZM7 -4.63954e-08L7 105.658L9 105.658L9 4.63954e-08L7 -4.63954e-08Z" fill="#4F3EFF" />
  </svg>
);

IconDown.defaultProps = {
  className: '',
  width: '1rem',
  height: '107px',
};
