import { BigNumber } from 'ethers';

export const getNetwork = (id: string) => {
  switch (id) {
    case '1':
      return 'Mainnet';
    case '5':
      return 'Goerli test';
    case '11155111':
      return 'Sepolia test';
    case '44787':
      return 'Celo test';
    default:
      return 'unknown';
  }
};

export const transformNumber = (value:BigNumber | number, decimals:number, reverse: boolean) => {
  if (value instanceof BigNumber) {
    const dec = BigNumber.from(10);
    if (reverse) {
      if (decimals > 2) {
        const floatValue = (value.div(dec.pow((decimals - 2 || 0)))).toNumber();
        return floatValue / 100;
      }

      return value.toNumber() * 10 ** (-decimals || 0);
    }
    return (value.mul(dec.pow((decimals || 0)))).toNumber();
  }
  if (reverse) {
    return value * 10 ** (-decimals || 0);
  }
  return value * 10 ** (decimals || 0);
};
