import { HelElement, HelFC } from '../../interfaces';
import { IconPropsInterface } from './interfaces';

export const IconArrowSmallRight: HelFC<IconPropsInterface> = ({
  width, height, className, onClick,
}): HelElement => (
  <svg
    className={`icon-svg ${className || 'icon-primary'}`}
    id="svg-icon-arrow"
    onClick={onClick}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M4.5 12L19.5 12M19.5 12L12.75 5.25M19.5 12L12.75 18.75" stroke="#4F3EFF" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconArrowSmallRight.defaultProps = {
  className: '',
  width: '1.5rem',
  height: '1.5rem',
};
