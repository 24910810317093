import { Link } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { CategoryTypes } from '../../constants';
import { HelElement, HelFC } from '../../interfaces';
import { ButtonBase } from '../../components';

import { getApp, getAppUrl } from '../../utils';

export const Footer: HelFC = (): HelElement => {
  const { subdomain } = getApp();
  const appUrl = getAppUrl();
  const { account } = useWeb3React();

  return (
    <div className="app-footer">
      {subdomain !== 'app' ? (

        <div className="links-block">
          <a
            href={window.location.href.replace(window.location.host, `app.${window.location.host}`)}
            target="_blank"
            rel="noreferrer"
          >
            <ButtonBase
              className="button-without-icon"
              label="Launch App"
              category={CategoryTypes.Filled}
            />
          </a>
          <a
            href={`${appUrl}/pools`}
            target="_blank"
            rel="noreferrer"
          >
            <span className="title">
              Pools
            </span>
          </a>
          <a href="/">
            <span className="title">
              Documentation
            </span>
          </a>
        </div>
      ) : (
        <>
          {account && (
          <div className="links-block">
            <Link to="/pools">
              <span className="title">
                Pools
              </span>
            </Link>
            <Link to="/">
              <span className="title">
                Documentation
              </span>
            </Link>
          </div>
          )}
        </>
      )}
      <div className="copyright">
        <div className="logo" />
        <span>HeliosDAO Copyright Limited</span>
      </div>
    </div>
  );
};
