import { AccountInterface } from './interfaces';
import { LocalConfig } from '../../../../services';
import { ConfigItemNameTypes } from '../../../../constants';
import { ItemType } from '../../../../interfaces/dataListInterfaces/DataParamsInterface';

export const setAccount = (state: AccountInterface, payload: AccountInterface) => {
  const newState = { ...state, ...payload };
  LocalConfig.setItem(ConfigItemNameTypes.Account, newState as ItemType);

  return newState;
};
