import { HelElement, HelFC } from '../../interfaces';
import { ArrowBadge, LongArrowBadge } from '../../components';
import { WorkFlowCard } from '../cards';

export const WorkFlowCardBox: HelFC = (): HelElement => {
  const symbol = process.env.REACT_APP_CHAIN_SYMBOL!;
  return (
    <div className="work-flow-box">
      <div className="card">
        <WorkFlowCard title={`1. Invest ${symbol}`} description={`Deposit ${symbol} into solar pools through Helios.`} />
      </div>
      <div className="badge-block">
        <ArrowBadge label="LP TOKENS" className="rotate180" />
        <ArrowBadge label={symbol} />
      </div>
      <div className="card">
        <WorkFlowCard
          title="2. Build Solar"
          description="Helios works with world-class solar developers to build high impact project around the world"
        />
      </div>
      <ArrowBadge label="CLEAR ENERGY" />
      <div className="card">
        <WorkFlowCard
          title="3. Sell Clean Energy"
          description="Our installations sell clean energy to commercial customers backed by 20-year PPA contracts."
        />
      </div>
      <div className="long-arrow">
        <LongArrowBadge label="YIELD" />
      </div>
    </div>
  );
};
