export enum AppModalActionTypes {
  ModalInfoSet = 'ModalInfoSet',
  MessageModal = 'MessageModal',
  DepositModal = 'DepositModal',
  InformModal = 'InformModal',
  InfoModal = 'InfoModal',
  MetamaskModal = 'MetamaskModal',
  MenuModal = 'MenuModal',
  Close = 'Close',
}
