import { AppLoaderStateInterface, AppLoaderStateReducerInterface } from './interfaces';
import { appLoaderInitialState } from './initialState';
import { AppLoaderActionTypes } from './actions';

export const appLoaderStateReducer: AppLoaderStateReducerInterface = (
  state = { ...appLoaderInitialState },
  action,
): AppLoaderStateInterface => {
  switch (action.type) {
    case AppLoaderActionTypes.Open:
      return {
        ...state,
        isOpen: true,
        ...action.payload,
      };

    case AppLoaderActionTypes.Close:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};
