import { useContext } from 'react';
import { HelElement, HelFC } from '../../interfaces';
import { PoolDetailBlock, ProgressBlock, ButtonBase } from '../../components';
import { InvestmentCardProps, PoolDetailProps } from './interfaces';
import { CategoryTypes, PoolStatusTypes } from '../../constants';
import {
  BlockchainPoolAccountInterface,
  BlockchainPoolInterface,
} from '../../store/context/states/ListState/interfaces';
import {
  AppModalActionTypes,
  WithdrawActionTypes,
  ConfigStateContext,
  WithdrawStateContext,
} from '../../store';
import { canWithdraw } from '../../utils/date';
import { DepositFunds } from '../modals/contents/DepositFunds';
import { WithdrawYield } from '../modals/contents';

export const InvestmentCard: HelFC<InvestmentCardProps> = ({
  className,
  pool,
}): HelElement => {
  const { dispatchAppModal } = useContext(ConfigStateContext);
  const { dispatchWithdrawState } = useContext(WithdrawStateContext);

  const {
    name,
    images,
    status,
    blockchainData = {} as BlockchainPoolInterface,
    blockchainAccountData = {} as BlockchainPoolAccountInterface,
  } = pool;

  const {
    totalDeposited,
    poolSize,
    lockupPeriod = 0,
    availableToWithdraw = 0,
    symbol,
    apy,
  } = blockchainData;

  const {
    depositDate = 0,
    accountInvestmentSize = 0,
    accountPoolBalance = 0,
  } = blockchainAccountData;

  const yieldBalance = accountPoolBalance - accountInvestmentSize;
  const isCompleted = canWithdraw(depositDate as number, lockupPeriod);
  const availableWithdrawInvestment = accountInvestmentSize > availableToWithdraw ? availableToWithdraw : accountInvestmentSize;

  const poolDetailList: PoolDetailProps[] = [
    {
      label: 'Your Deposit',
      value: accountInvestmentSize || 0,
      suffix: symbol,
    },
    {
      label: 'Your Balance',
      value: accountPoolBalance || 0,
      suffix: symbol,
    },
    {
      label: 'APY',
      value: apy || 0,
      suffix: '%',
    },
    {
      label: 'Hold period',
      value: lockupPeriod || 0,
      suffix: lockupPeriod > 0 ? 'days' : 'day',
    },
  ];
  const accountPoolDetailList: PoolDetailProps[] = [
    {
      label: 'Invested',
      value: accountInvestmentSize || 0,
      suffix: symbol,
    },
    {
      label: 'Yield',
      value: yieldBalance || 0,
      suffix: symbol,
    },
  ];

  const openDepositModal = () => {
    dispatchAppModal({
      type: AppModalActionTypes.DepositModal,
      payload: {
        title: 'Deposit funds',
        contentTypes: [],
        data: pool,
        content: <DepositFunds />,
        disabled: true,
      },
    });
  };

  const openWithdrawModal = () => {
    dispatchWithdrawState({
      type: WithdrawActionTypes.WithdrawBalanceUpdate,
      payload: {
        yield: yieldBalance,
        investment: availableWithdrawInvestment,
      },
    });

    dispatchAppModal({
      type: AppModalActionTypes.DepositModal,
      payload: {
        title: 'Yield',
        contentTypes: [],
        data: pool,
        content: <WithdrawYield />,
        disabled: !yieldBalance,
      },
    });
  };
  return (
    <div className={`investment-card ${className || ''}`}>
      {images?.length ? <img src={images[0].urls.original} alt="pool 1" />
        : <div className="skeleton" />}
      <h4>{name}</h4>
      {accountInvestmentSize ? (
        <div className="apy">
          {`APY ${apy}%`}
        </div>
      ) : <ProgressBlock value={poolSize || 0} currentValue={totalDeposited || 0} label="Pool size:" suffix={symbol} />}
      {
        accountInvestmentSize ? (
          <div className="account-investment-info">
            <div className="account-balance">
              <span className="label">
                Balance
              </span>
              <div className="value">
                <span>{accountPoolBalance || 0}</span>
                {symbol && <span className="symbol">{symbol}</span>}
              </div>
              <div onClick={openWithdrawModal} className="withdraw">Withdraw</div>
            </div>
            <div className="investment-details">
              {
                accountPoolDetailList.map(
                  ({ value, label }, index) => (
                    // <div key={index}>
                    <PoolDetailBlock key={index} value={value} label={label as string} suffix={symbol} />
                    // </div>
                  ),
                )
              }
            </div>
          </div>
        ) : (
          <div className="pool-investment-info">
            <div className="investment-details">
              {
                poolDetailList.map(({ value, label, suffix }, index) => (
                  <div key={index}>
                    <PoolDetailBlock value={value} label={label as string} suffix={suffix} />
                  </div>
                ))
              }
            </div>
          </div>
        )
      }
      {accountInvestmentSize ? (
        <div className="action-buttons">
          <ButtonBase
            label="Add Funds"
            category={status === PoolStatusTypes.Closed ? CategoryTypes.Disabled : CategoryTypes.Filled}
            onClick={openDepositModal}
          />
          <ButtonBase
            label="Reinvest Yield"
            category={isCompleted ? CategoryTypes.Outlined : CategoryTypes.Disabled}
            onClick={openWithdrawModal}
            disabled={!accountInvestmentSize}
          />
        </div>
      )
        : (
          <div className="action-buttons">
            <ButtonBase
              label="Invest!"
              category={status === PoolStatusTypes.Closed ? CategoryTypes.Disabled : CategoryTypes.Filled}
              onClick={openDepositModal}
            />
          </div>
        )}
    </div>
  );
};
