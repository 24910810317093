import { cloneDeep } from 'lodash';
import {
  WithdrawStateInterface,
} from './interfaces';

export const updateWithdrawSteps = (state: WithdrawStateInterface, type: string, payload?: string) => {
  const newState = cloneDeep(state);
  if (type === 'delete') {
    const newSteps = newState.steps;
    newSteps?.splice(-1);
    newState.steps = newSteps;
  }

  if (type === 'add') {
    const newSteps = newState.steps;
    if (payload) {
      newSteps?.push(payload);
    }
    newState.steps = newSteps;
  }

  return newState;
};
