import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.scss';
import { Web3ReactProvider } from '@web3-react/core';
import { ExternalProvider, JsonRpcFetchFunc, Web3Provider } from '@ethersproject/providers';
import {
  ConfigStateProvider,
  ListStateProvider,
  PlatformStateProvider,
  AccountStateProvider,
  TransactionStateProvider,
  WithdrawStateProvider,
} from './store';
import { AppContainer } from './modules';

function getLibrary(provider: ExternalProvider | JsonRpcFetchFunc) {
  return new Web3Provider(provider);
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <ConfigStateProvider>
        <PlatformStateProvider>
          <AccountStateProvider>
            <TransactionStateProvider>
              <WithdrawStateProvider>
                <ListStateProvider>
                  <AppContainer />
                </ListStateProvider>
              </WithdrawStateProvider>
            </TransactionStateProvider>
          </AccountStateProvider>
        </PlatformStateProvider>
      </ConfigStateProvider>
    </Web3ReactProvider>
  </React.StrictMode>,
);
