import { RequestStatusTypes } from '../../constants';
import { HTTPResponseErrorTypes } from '../../interfaces/RequestDataTypes';

export interface RequestStateInterface {
  status: RequestStatusTypes,
  isFirstRequest: boolean,
  errorMessage: string | null | HTTPResponseErrorTypes,
  successMessage: string | null,
  responseError: unknown,
  responseData: unknown,
}

export enum DataHandlerActionTypes {
  SetLoading = 'SetLoading',
  SetError = 'SetError',
  SetSuccess = 'SetSuccess',
  SetData = 'SetData',
  ResetState = 'ResetState',
}

export type RequestActionType = {
  type:DataHandlerActionTypes, payload?:RequestStatusTypes | string | boolean | HTTPResponseErrorTypes,
};
