import { PlatformInterface } from './interfaces';
import { platformInitialState } from './initialState';

const localStateName = 'helios_platform';

export const getLocalPlatform = (): PlatformInterface => {
  const localPlatforms = localStorage.getItem(localStateName) ? JSON.parse(
    localStorage.getItem(localStateName) || '',
  ) : null;
  return localPlatforms || { ...platformInitialState };
};

export const setLocalPlatform = (
  state: PlatformInterface,
): PlatformInterface => {
  localStorage.setItem(localStateName, JSON.stringify(state));
  return state;
};
