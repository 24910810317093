import { IconPropsInterface } from './interfaces';
import { HelElement, HelFC } from '../../interfaces';

export const IconLoaderPrimary: HelFC<IconPropsInterface> = (props: IconPropsInterface): HelElement => {
  const { width, height, className } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      style={{ width, height }}
      viewBox="0 0 44 44"
      className={`icon-svg ${className}`}
    >
      <g
        fill="none"
        fillRule="evenodd"
        strokeWidth="2"
      >
        <circle cx="22" cy="22" r="1">
          <animate
            attributeName="r"
            begin="0s"
            dur="1.8s"
            values="1; 20"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="0s"
            dur="1.8s"
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
        </circle>
        <circle
          cx="22"
          cy="22"
          r="1"
        >
          <animate
            attributeName="r"
            begin="-0.9s"
            dur="1.8s"
            values="1; 20"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.165, 0.84, 0.44, 1"
            repeatCount="indefinite"
          />
          <animate
            attributeName="stroke-opacity"
            begin="-0.9s"
            dur="1.8s"
            values="1; 0"
            calcMode="spline"
            keyTimes="0; 1"
            keySplines="0.3, 0.61, 0.355, 1"
            repeatCount="indefinite"
          />
        </circle>
      </g>
    </svg>
  );
};

IconLoaderPrimary.defaultProps = {
  width: '2.75rem',
  height: '2.75rem',
  className: 'icon-loader-primary',
};
