import { HelElement, HelFC } from '../../interfaces';
import { CompanyCardBoxProps } from './interfaces';

// const companies = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const CompanyCardBox: HelFC<CompanyCardBoxProps> = ({ items }): HelElement => (
  <div className="company-cards-box">
    {items?.map(({ urls }, index) => (
      <div key={`company-card-${index}`}>
        <div className="image">
          {urls?.original ? <img src={urls?.original} alt="member 1" />
            : <div className="skeleton" />}
        </div>
      </div>
    ))}
  </div>
);

// export const CompanyCardBox: HelFC<CompanyCardBoxProps> = ({ items }): HelElement => (
//   <div className="company-cards-box">
//     {items?.map(({
//                    urls,
//                  }, index) => (
//       <div key={`company-card-${index}`}>
//         <div className="image">
//           {urls?.original ? <img src={urls?.original} alt="member 1" />
//             : <div className="skeleton" />}
//         </div>
//       </div>
//     ))}
//   </div>
// );
