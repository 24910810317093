import { HelElement, HelFC } from '../../interfaces';
import { MenuIconPropsInterface } from './interfaces';

export const IconMenu: HelFC<MenuIconPropsInterface> = ({ onClick, isOpen, color }): HelElement => (
  <div className={`${isOpen ? 'open' : ''} menu-icon-${color}`} onClick={onClick} id="nav-icon3">
    <span />
    <span />
    <span />
    <span />
  </div>
);
