import { HelElement, HelFC } from '../../interfaces';
import { IconPropsInterface } from './interfaces';

export const IconInfo: HelFC<IconPropsInterface> = ({
  width, height, className, onClick, color,
}): HelElement => (
  <svg
    className={`icon-svg ${className || 'icon-primary'}`}
    id="svg-icon-arrow"
    onClick={onClick}
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1763_69)">
      <path
        d="M10.6667 17.9438V10.8327H13.0556V17.9438H10.6667ZM11.8611 0.166016C15.1389
         0.166016 17.9352 1.32342 20.25 3.63824C22.5648 5.95305 23.7222 8.74009 23.7222
          11.9993C23.7222 15.2586 22.5648 18.0456 20.25 20.3605C17.9352 22.6753 15.1389
           23.8327 11.8611 23.8327C8.58333 23.8327 5.78704 22.6753 3.47222 20.3605C1.15741
           18.0456 0 15.2586 0 11.9993C0 8.74009 1.15741 5.95305 3.47222 3.63824C5.78704
           1.32342 8.58333 0.166016 11.8611 0.166016ZM11.8611 21.4993C14.4722 21.4993
            16.7037 20.5734 18.5556 18.7216C20.4074 16.8697 21.3333 14.629 21.3333
             11.9993C21.3333 9.36972 20.4074 7.12898 18.5556 5.27713C16.7037 3.42527
              14.4722 2.49935 11.8611 2.49935C9.25 2.49935 7.01852 3.42527 5.16667
              5.27713C3.31481 7.12898 2.38889 9.36972 2.38889 11.9993C2.38889 14.629
              3.31481 16.8697 5.16667 18.7216C7.01852 20.5734 9.25 21.4993 11.8611
              21.4993ZM10.6667 8.44379V6.0549H13.0556V8.44379H10.6667Z"
        fill={color}
      />
    </g>
    <defs>
      <clipPath id="clip0_1763_69">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

IconInfo.defaultProps = {
  className: '',
  width: '1.25rem',
  height: '1.25rem',
  color: '#4F3eFF',
};
