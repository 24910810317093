export enum DirectionTypes {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
  Left = 'left',
  Up = 'up',
  Right = 'right',
  Down = 'down',
}

export enum AlignTypes {
  TL = 'tl',
  TC = 'tc',
  TR = 'tr',
  CL = 'cl',
  CC = 'cc',
  CR = 'cr',
  BL = 'bl',
  BC = 'bc',
  BR = 'br',
}

export const DeviceBreakpoints = {
  Desktop: { min: 1101, max: Infinity },
  Mobile: { min: 0, max: 1100 },
};

export enum DeviceTypes {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export enum PoolStatusTypes {
  Draft = 'Draft',
  Deployed = 'Deployed',
  Active = 'Active',
  Closed = 'Closed',
}

export enum ProjectStatusTypes {
  PreClosing = 'PreClosing',
  Active = 'Active',
  Exited = 'Exited',
  Dead = 'Dead',
}

export enum RegionTypes {
  All = 'All',
  None = 'None',
  Europe = 'Europe',
  Americas = 'Americas',
  Asia = 'Asia',
  Africa = 'Africa',
  Oceania = 'Oceania',
  Antarctic = 'Antarctic',
}

export const REGIONS = [RegionTypes.All, RegionTypes.Americas, RegionTypes.Asia, RegionTypes.Africa, RegionTypes.Europe];

export enum ConfigItemNameTypes {
  Platform = 'platform',
  Transactions = 'transactions',
  Account = 'account',
}

export enum TransactionStatusTypes {
  Pending = 'Pending',
  Success = 'Success',
  Fail = 'Fail',
  Default = 'Default',
}
