import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
  ChartData,
  ChartOptions,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useRef } from 'react';
import moment from 'moment';
import { HelFC } from '../../interfaces';
import { ChartCardProps } from './interfaces';
import { numberWithCommas } from '../../pages/helpers';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
);

const getOrCreateTooltip = (chart: { canvas: { parentNode: { querySelector: (arg0: string) => any; appendChild: (arg0: any) => void; }; }; }) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.backgroundColor = '#fff';
    tooltipEl.style.boxShadow = '1px 1px 12px 0px rgba(57, 19, 105, 0.24)';
    tooltipEl.style.borderRadius = '6px';
    tooltipEl.style.color = '#1B0F30';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'absolute';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.style.fontSize = '12px';
    tooltipEl.style.padding = '8px';

    const table = document.createElement('table');

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context: { chart: any; tooltip: any; }) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const bodyLines = tooltip.body.map((b: { lines: never; }) => b.lines);

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body: string) => {
      const tr = document.createElement('tr');
      const td = document.createElement('td');
      const text = document.createTextNode(body);

      td.appendChild(text);
      tr.appendChild(td);
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot?.firstChild) {
      tableRoot.firstChild.remove();
    }

    // Add new children
    tableRoot.appendChild(tableBody);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.boxShadow = '1px 1px 12px 0px rgba(57, 19, 105, 0.24)';
  tooltipEl.style.backgroundColor = '#fff';
  tooltipEl.style.color = '#1B0F30';
  tooltipEl.style.left = `${positionX + tooltip.caretX}px`;
  tooltipEl.style.top = `${positionY + tooltip.caretY / 2}px`;
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.fontSize = '12px';
  tooltipEl.style.padding = '8px';
};

export const getOptions = (symbol: string):ChartOptions<'line'> => ({
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
      position: 'average',
      external: externalTooltipHandler,
      callbacks: {
        label: (context) => {
          let label = context.dataset.label || '';
          if (context.parsed.y !== null) {
            label += `${numberWithCommas(context.parsed.y)} ${symbol}`;
          }
          return label;
        },
      },
    },
  },
  scales: {
    y: {
      border: {
        display: false,
      },
      beginAtZero: true,
      ticks: {
        callback(tickValue) {
          // Format the scale numbers
          let formattedValue = tickValue;
          if (Number(tickValue) >= 1000) {
            formattedValue = `${Number(tickValue) / 1000}k`;
          }
          return formattedValue; // Adds a dollar sign before each value
        },
        color: '#1B0F30',
      },
    },
    x: {
      beginAtZero: true,
      ticks: {
        color: '#1B0F30',
      },
      grid: {
        display: false, // Hide the vertical lines of the chart grid
      },
    },
  },
});

// const labels = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

// tooltipLine block
const tooltipLine = {
  id: 'tooltipLine',
  afterDraw: (chart: any) => {
    // eslint-disable-next-line no-underscore-dangle
    const dataset = chart._metasets[0].data; // Assuming you want to get the last point of the first dataset

    const activePoint = dataset[dataset.length - 1];
    const { ctx } = chart;
    ctx.save();
    ctx.beginPath();
    ctx.moveTo(activePoint.x, activePoint.y + 5);
    ctx.lineTo(activePoint.x, chart.chartArea.bottom);
    ctx.lineWidth = 2;
    ctx.strokeStyle = '#7018FF';
    ctx.stroke();
    /* ctx.restore() */
  },

};

export const ChartCard: HelFC<ChartCardProps> = ({ chart }) => {
  const chartRef = useRef<any>(null);

  const { title = '', info = [], data = [] } = chart || {};
  const expectedData = new Array(data.length);
  expectedData.push(data[data.length - 1], data[data.length - 1] + 1000);
  // const currentLabels = labels.slice(0, expectedData.length);
  const labels = [];
  // eslint-disable-next-line no-plusplus
  for (let i = expectedData.length; i >= 0; i--) {
    const month = moment().subtract(i, 'months');
    const label = month.format('MMM').toUpperCase();
    // data.push({
    //   x: month.toDate(),
    //   y: Math.random() * 100, // Replace with your own data source
    // });

    labels.push(label);
  }
  const chartData: ChartData<'line'> = {
    labels, // currentLabels,
    datasets: [
      {
        data: data as number[],
        backgroundColor: (context: any) => {
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: {
              top,
              bottom,
            },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, '#8000FF60');
          gradientBg.addColorStop(0.54, '#565DFF60');
          gradientBg.addColorStop(1, '#FFF');
          // eslint-disable-next-line consistent-return
          return gradientBg;
        },
        borderColor: '#7018FF',
        pointBackgroundColor: '#7018FF',
        pointRadius: (context: any) => {
          const value = (context.dataset.data.length - 1) === context.dataIndex;

          return value
            ? 4
            : 0;
        },
        pointBorderColor: '#fff',
        borderWidth: 2,
        pointBorderWidth: (context: any) => {
          console.log(context.dataset);
          const value = (context.dataset.data.length - 1) === context.dataIndex;

          return value
            ? 1
            : 0;
        },
        tension: 0.5,
        fill: 'origin',
      },
      {
      // eslint-disable-next-line no-sparse-arrays
        data: expectedData as number[],
        backgroundColor: (context) => {
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: {
              top,
              bottom,
            },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          // gradientBg.addColorStop(0, '#8000FF40');
          gradientBg.addColorStop(0, '#565DFF05');
          gradientBg.addColorStop(1, '#FFF');
          // eslint-disable-next-line consistent-return
          return gradientBg;
        },
        borderColor: '#7018FF50',
        borderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 0,
        pointHoverRadius: 0,
        tension: 0.5,
        fill: 'origin',
      },
    ],
  };
  // const generateData = (currentChart: any) => {
  //   // const data = [];
  //
  //   // Generate labels and data for the last 12 months
  //   // eslint-disable-next-line no-plusplus
  //   for (let i = 11; i >= 0; i--) {
  //     const month = moment().subtract(i, 'months');
  //     const label = month.format('MMM');
  //     // data.push({
  //     //   x: month.toDate(),
  //     //   y: Math.random() * 100, // Replace with your own data source
  //     // });
  //
  //     currentChart.data.labels.push(label);
  //   }
  //
  //   // Update the chart's datasets with new data
  //   // currentChart.data.datasets[0].data = data;
  //
  //   // Remove the oldest label and data point
  //   // currentChart.data.labels.shift();
  //   // currentChart.data.datasets[0].data.shift();
  //
  //   // Update the chart
  //   currentChart.update();
  //
  //   // Repeat the process every second
  //   // setTimeout(generateData, 1000);
  // };
  //
  // if (chartRef.current) {
  //   // Start generating data
  //   generateData(chartRef.current);
  // }

  // useEffect(() => {
  //   if (chartRef.current) {
  //     // Start generating data
  //     generateData(chartRef.current);
  //   }
  // }, [chartRef.current]);

  return (
    <div className="chart-card">
      <h3>{title}</h3>
      <div className="chart-info">
        {info?.map(({ label = '', value = '' }) => (
          <div className="chart-info-item">
            <div className="label">{label}</div>
            <div className="value">{value}</div>
          </div>
        ))}
      </div>
      <div className="chart-container">
        <Line ref={chartRef} options={getOptions('CELO')} data={chartData} plugins={[tooltipLine]} />
      </div>
    </div>
  );
};
