import { HelElement, HelFC } from '../../interfaces';
import { IconPropsInterface } from './interfaces';

export const IconDiscord: HelFC<IconPropsInterface> = ({ width, height, className }): HelElement => (
  <svg
    className={`icon-svg ${className || 'icon-primary'}`}
    id="svg-icon-discord"
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2944 12.223C16.2826 12.223 15.4683 13.1837 15.4683 14.3847C15.4683 15.559 16.2826 16.5197 17.2944
        16.5197C18.3308 16.5197 19.1451 15.559 19.1451 14.3847C19.1451 13.1837 18.3308 12.223 17.2944 12.223ZM10.755
        12.223C9.74324 12.223 8.90423 13.1837 8.90423 14.3847C8.90423 15.559 9.74324 16.5197 10.755 16.5197C11.7667
        16.5197 12.5811 15.559 12.5811 14.3847C12.5811 13.1837 11.7667 12.223 10.755 12.223ZM19.0217 21.0833C19.0217
        21.0833 18.3801 20.2293 17.8373 19.5088C20.2062 18.7882 21.0946 17.1869 21.0946 17.1869C20.3543 17.7207
         19.6387 18.0943 19.0217 18.3345C18.1087 18.7348 17.245 19.0284 16.406 19.1885C14.6786 19.5354 13.0993
         19.4554 11.7421 19.1885C10.7056 18.975 9.81727 18.6547 9.10164 18.3345C8.68214 18.1744 8.23796 17.9609
         7.76909 17.6673C7.71974 17.6406 7.67039 17.6139 7.62103 17.5605C7.57168 17.5605 7.547 17.5339 7.52233
         17.5339C7.20153 17.3204 7.02879 17.1869 7.02879 17.1869C7.02879 17.1869 7.89248 18.7348 10.1874
         19.4821C9.64454 20.2293 8.97826 21.0833 8.97826 21.0833C5.00529 20.9499 3.5 18.121 3.5 18.121C3.5
         11.8494 6.09107 6.77867 6.09107 6.77867C8.68214 4.67034 11.1251 4.7504 11.1251 4.7504L11.3226
         4.9639C8.06522 5.97804 6.58461 7.52593 6.58461 7.52593C6.58461 7.52593 6.97944 7.28574 7.67039
         6.96549C9.59518 6.0581 11.1005 5.81791 11.7421 5.73785C11.8408 5.73785 11.9395 5.71116 12.0382
         5.68447C13.1486 5.55103 14.3825 5.49766 15.6904 5.65778C17.3931 5.87129 19.2192 6.43173 21.0946
         7.52593C21.0946 7.52593 19.688 6.0581 16.6281 5.04396L16.8749 4.7504C16.8749 4.7504 19.3179 4.67034
         21.9089 6.77867C21.9089 6.77867 24.5 11.8494 24.5 18.121C24.5 18.121 22.9947 20.9499 19.0217 21.0833Z"
      fill="#4F3EFF"
    />
  </svg>
);

IconDiscord.defaultProps = {
  className: '',
  width: '1.75rem',
  height: '1.75rem',
};
