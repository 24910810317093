import { useContext, useState } from 'react';
import { HelElement, HelFC } from '../../../interfaces';
import { CategoryTypes, ColorTypes } from '../../../constants';
import { ButtonBase } from '../../../components';
import headerGradient from '../../../assets/images/gradients/Header.png';
import { getAppUrl, useMediaQuery } from '../../../utils';
import { IconMenu } from '../../../components/icons/IconMenu';
import { AppModalActionTypes, ConfigStateContext } from '../../../store';

export const AppHeader: HelFC = (): HelElement => {
  const { dispatchAppModal } = useContext(ConfigStateContext);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [menuIconColor, setMenuIconColor] = useState(ColorTypes.Primary);

  const appUrl = getAppUrl();
  const isMobile = useMediaQuery('(max-width: 600px)');

  const handleMenuClick = () => {
    setMenuIsOpen(!menuIsOpen);
    if (!menuIsOpen) {
      window.document.body.style.overflowY = 'hidden';
      setMenuIconColor(ColorTypes.Secondary);
      dispatchAppModal({
        type: AppModalActionTypes.MenuModal,
        payload: {
          isClosable: false,
          content:
  <div className="menu-links-block">
    <a
      href={`${appUrl}/pools`}
      target="_blank"
      rel="noreferrer"
    >

      Pools
    </a>
    <a href="/">

      Documentation
    </a>
    <a
      href={window.location.href.replace(window.location.host, `app.${window.location.host}`)}
      target="_blank"
      rel="noreferrer"
    >
      <ButtonBase
        className="button-without-icon"
        label="Launch App"
        category={CategoryTypes.Outlined}
      />
    </a>
  </div>,
        },
      });
    } else {
      window.document.body.style.overflowY = 'scroll';
      setMenuIconColor(ColorTypes.Primary);
      dispatchAppModal({ type: AppModalActionTypes.Close });
    }
  };

  return (
    <div className="header">
      <img className="gradient" src={headerGradient} alt="" />
      <a href="/">
        <div className="header-logo" />
      </a>
      {
        !isMobile
          ? (
            <div className="links-block">
              <a
                href={`${appUrl}/pools`}
                target="_blank"
                rel="noreferrer"
              >
                <span>
                  Pools
                </span>
              </a>
              <a href="/">
                <span className="title">
                  Documentation
                </span>
              </a>
              <a
                href={window.location.href.replace(window.location.host, `app.${window.location.host}`)}
                target="_blank"
                rel="noreferrer"
              >
                <ButtonBase
                  className="button-without-icon"
                  label="Launch App"
                  category={CategoryTypes.Filled}
                />
              </a>
            </div>
          )
          : (
            <IconMenu
              onClick={handleMenuClick}
              isOpen={menuIsOpen}
              color={menuIconColor}
            />
          )
      }
    </div>
  );
};
