import { createContext, useMemo, useReducer } from 'react';
import {
  ListStateContextInterface,
  ListActionType,
  ListInterface,
} from './interfaces';
import { listInitialState } from './initialState';
import { listStateReducer } from './reducer';
import { ContextProviderPropsInterface, UseReducerType } from '../../../interfaces';
import { HelElement, HelFC } from '../../../../interfaces';

export const ListStateContext = createContext<ListStateContextInterface>(
  {} as ListStateContextInterface,
);

export const ListStateProvider
:HelFC<ContextProviderPropsInterface> = ({ children }): HelElement => {
  const [
    listState,
    dispatchListState,
  ] = useReducer<
  UseReducerType<ListInterface, ListActionType>
  >(listStateReducer, listInitialState);

  const value = useMemo(() => ({
    listState,
    dispatchListState,
  }), [listState]);

  return (
    <ListStateContext.Provider
      value={value}
    >
      {children}
    </ListStateContext.Provider>
  );
};
