import { HelElement, HelFC } from '../../interfaces';
import { IconPropsInterface } from './interfaces';

export const IconArrowLong: HelFC<IconPropsInterface> = ({ width, height, className }): HelElement => (
  <svg
    className={`icon-svg ${className || 'icon-primary'}`}
    width={width}
    height={height}
    viewBox="0 0 54 538"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M1 537H0V538H1V537ZM1 8V7H0V8H1ZM53.3614 8.70711C53.7519 8.31658 53.7519 7.68342 53.3614 7.29289L46.9975 0.928932C46.6069 0.538408 45.9738 0.538408 45.5833 0.928932C45.1927 1.31946 45.1927 1.95262 45.5833 2.34315L51.2401 8L45.5833 13.6569C45.1927 14.0474 45.1927 14.6805 45.5833 15.0711C45.9738 15.4616 46.6069 15.4616 46.9975 15.0711L53.3614 8.70711ZM52.6543 536H1V538H52.6543V536ZM2 537V8H0V537H2ZM1 9H52.6543V7H1V9Z" fill="#4F3EFF" />
  </svg>

);

IconArrowLong.defaultProps = {
  className: '',
  width: '54px',
  height: '538px',
};
