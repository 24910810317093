import {
  AccountActionType,
  AccountInterface,
} from './interfaces';

import { AccountActionTypes } from './actions';
import { UseReducerType } from '../../../interfaces';
import { getLocalAccount } from './localStore';
import { setAccount } from './helpers';
import { accountInitialState } from './initialState';
import { LocalConfig } from '../../../../services';
import { ConfigItemNameTypes } from '../../../../constants';
import { ItemType } from '../../../../interfaces/dataListInterfaces/DataParamsInterface';

export const accountStateReducer: UseReducerType<AccountInterface, AccountActionType> = (state, action)
:AccountInterface => {
  switch (action.type) {
    case AccountActionTypes.GetAccountLocal:
      return getLocalAccount();

    case AccountActionTypes.SetAccount:
      LocalConfig.setItem(ConfigItemNameTypes.Account, action.payload as ItemType);
      return setAccount(state, action.payload);

    case AccountActionTypes.DeleteAccount:
      return { ...accountInitialState };
    default:
      return state;
  }
};
