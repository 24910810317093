import { HelElement, HelFC } from '../../interfaces';
import { ProjectCardProps } from './interfaces';

export const ProjectCard: HelFC<ProjectCardProps> = ({
  poolName,
  name,
  overview,
  image,
  className,
}): HelElement => (
  <div className={`project-card ${className || ''}`}>
    <div className="project-image">
      <img src={image} alt="project 1" />
    </div>
    <div className="project-info">
      <span className="pool-name"><h6>{poolName}</h6></span>
      <h3>{name}</h3>
      <span className="description">{overview}</span>
    </div>
  </div>
);
