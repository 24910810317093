import {
  useContext, useState,
} from 'react';
import { HelElement, HelFC } from '../../../interfaces';
import {
  AppModalActionTypes,
  ConfigStateContext,
  ListStateContext,
  WithdrawStateContext,
} from '../../../store';
import { ContentTypes } from './interface';
import { ActionButtons } from '../shared/ActionButtons';
import { ReinvestCard } from '../../cards';
import { getLockupPeriodHint } from './helpers';
import { CategoryTypes, PoolStatusTypes } from '../../../constants';
import { FinalizeInvestment } from './FinalizeInvestment';
import { PoolStatus } from '../../../store/context/states/ListState/interfaces';

export const ReinvestYield: HelFC = (): HelElement => {
  const {
    appModal: {
      data,
      contentTypes = [],
      disabled,
    },
    dispatchAppModal,
  } = useContext(ConfigStateContext);
  const { listState: { pools } } = useContext(ListStateContext);
  const reinvestPools = pools?.filter((pool) => pool.status === PoolStatusTypes.Active && pool.blockchainData?.poolStatus === PoolStatus.Finalized) || [];

  const [activeIndex, setActiveIndex] = useState(0);
  const { withdrawState } = useContext(WithdrawStateContext);
  const {
    yield: yieldBalance = 0,
  } = withdrawState;
  const { symbol } = data?.blockchainData || {};

  const handleSubmit = () => {
    contentTypes.push(ContentTypes.ReinvestYield);
    dispatchAppModal({
      type: AppModalActionTypes.ModalInfoSet,
      payload: {
        title: 'Finalize Reinvestment',
        contentTypes,
        content: <FinalizeInvestment pool={reinvestPools[activeIndex]} value={yieldBalance} isReinvest />,
      },
    });
  };

  return (
    <div className="modal-content reinvest-yield">
      <div className="reinvest-sub-title">
        <h6>{`${yieldBalance} ${symbol}`}</h6>
      </div>
      <div className="reinvest-pool-cards-box">
        {reinvestPools?.map((pool, index) => {
          if (index < 2) {
            return <ReinvestCard pool={pool} title="Pool title" onClick={() => { setActiveIndex(index); }} active={index === activeIndex} />;
          }
          return <></>;
        })}
      </div>
      <ActionButtons
        items={[
          {
            label: 'Cancel',
          },
          {
            category: CategoryTypes.Filled,
            label: 'Next',
            callBack: handleSubmit,
            disabled,
          },
        ]}
      />
      <p className="hint-message">{getLockupPeriodHint(data?.blockchainData?.lockupPeriod)}</p>
    </div>
  );
};
