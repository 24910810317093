export const API_ROOT = process.env.REACT_APP_URL_API ? process.env.REACT_APP_URL_API.toString() : '';

export enum HttpRequestHeaderTypes {
  Json = 'JSON',
  MultiPart = 'MultiPart',
}

export enum RequestStatusTypes {
  Waiting = 'Waiting',
  Loading = 'Loading',
  Success = 'Success',
  Error = 'Error',
}

export enum RequestTypes {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
  Patch = 'PATCH',
}
export enum ListNameTypes {
  Projects = 'projects',
  Pools = 'pools',
  Companies = 'companies',
  Team = 'team',
  Testimonials = 'testimonials',
  Stories = 'success-stories',
}

export const PLATFORM_API = {
  GET_PLATFORM: `${API_ROOT}/platform`,
};

export const ACCOUNT_API = {
  SET_ACCOUNT: `${API_ROOT}/identifications`,
  CHECK_PERSONA_VALIDATION: `${API_ROOT}/identifications`,
};

export const POOL_LIST_API = {
  GET_POOLS: `${API_ROOT}/${ListNameTypes.Pools}`,
};

export const PROJECT_LIST_API = {
  GET_PROJECTS: `${API_ROOT}/${ListNameTypes.Projects}`,
};

export const LIST_API = {
  GET_COMPANIES: `${API_ROOT}/${ListNameTypes.Companies}`,
  GET_TESTIMONIALS: `${API_ROOT}/${ListNameTypes.Testimonials}`,
  GET_TEAM: `${API_ROOT}/${ListNameTypes.Team}`,
  GET_SUCCESS_STORIES: `${API_ROOT}/${ListNameTypes.Stories}`,
};
