import { HelElement, HelFC } from '../../interfaces';
import metamaskIcon from '../../assets/images/metamask-icon.svg';
import { ModalBase } from './ModalBase';
import { useConnectWallet } from '../../services';

export const MetamaskModal:HelFC = (): HelElement => {
  const { connectWallet } = useConnectWallet();

  return (
    <ModalBase className="modal-medium metamask">
      <button onClick={connectWallet} className="metamask-content">
        <img src={metamaskIcon} alt="Metamask Icon" />
        <h3 className="title">Metamask</h3>
      </button>
    </ModalBase>
  );
};
