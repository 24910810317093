import { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { StoryCard } from '../modules';
import { HelElement, HelFC } from '../interfaces';
import { PageLayout } from '../modules';
import { ListStateContext } from '../store';
import testimonialsGradient from '../assets/images/gradients/OurMission.png';
import { StoryInterface } from '../store/context/states/ListState/interfaces';

export const Story: HelFC = (): HelElement => {
  const { listState: { stories } } = useContext(ListStateContext);

  const params = useParams();
  const activeStory = stories?.find(({ id }) => id === params.id);
  const otherStories = stories?.filter(({ id }) => id !== params.id) || [];

  useEffect(() => {
    const storiesBg = document.getElementById('stories');

    window.addEventListener('scroll', () => {
      const value = window.scrollY;
      if (storiesBg) {
        storiesBg.style.top = `${(-700 + value * 0.2)}px`;
      }
    });
  }, []);

  return (
    <PageLayout className="story-page">
      <StoryCard
        className="active"
        item={activeStory as StoryInterface || {}}
      />
      <div className="sub-content">
        <div className="article">
          <h4>Here’s a Title for the Article</h4>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Amet, eget ultrices enim est, aenean. Amet pharetra
            pellentesque tellus ut lectus adipiscing diam vel pretium. Massa viverra arcu pellentesque lectus viverra.
            Sed mauris faucibus gravida volutpat sagittis ultricies. Neque suspendisse dignissim felis, in. Odio a
            aliquet fermentum feugiat ut turpis nec egestas cum. Pellentesque venenatis cras dolor mauris integer enim
            sit. Ut velit, platea eu pretium leo quis dignissim sit. Eu vitae, pellentesque purus, consequat viverra vel
            ipsum.

            Tempor sagittis aliquam consequat adipiscing at. Ut mauris, et eu porttitor mi, convallis. Sed semper amet,
            commodo in elementum. Ac interdum arcu mi sagittis tincidunt ipsum rhoncus mattis pharetra. Vitae tristique
            at amet semper vitae aliquam. Enim, luctus fusce facilisi nec, consequat habitant etiam venenatis. Orci,
            diam fames netus condimentum. Risus natoque dictum purus nulla nulla. Enim ultrices dignissim pretium
            pharetra urna. Nulla habitant sapien lacus neque morbi sit nibh in.
          </p>
        </div>

      </div>
      <div className="stories">
        <img id="stories" className="gradient" src={testimonialsGradient} alt="" />
        <div className="title-description">
          <h2>Other Stories</h2>
        </div>
        <div className="cards-slide">
          <div className="card-container">
            {otherStories?.map((story, index) => (
              <StoryCard
                key={`story-card-${index}`}
                item={story}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
    </PageLayout>
  );
};
