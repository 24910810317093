import axios, { AxiosResponse } from 'axios';
import { HTTPRequestDataTypes, HTTPRequestParamsTypes, HttpRequestProps } from './interfaces';
import { httpHeader } from './httpHeader';

export const httpRequest = async ({
  data, params, url, method, withoutToken, cancelToken, headerType,
}:HttpRequestProps<HTTPRequestDataTypes, HTTPRequestParamsTypes>): Promise<AxiosResponse> => {
  try {
    return await axios({
      url,
      data: data || null,
      params: params || null,
      method,
      headers: { ...httpHeader({ withoutToken, headerType }) },
      cancelToken: cancelToken || undefined,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
