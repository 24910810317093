import { useContext } from 'react';
import { NumericFormat } from 'react-number-format';
import { HelElement, HelFC } from '../../../interfaces';
import {
  AppModalActionTypes,
  ConfigStateContext,
  // ListStateContext,
  WithdrawActionTypes,
  WithdrawStateContext,
} from '../../../store';
import { ContentTypes } from './interface';
import { ActionButtons } from '../shared/ActionButtons';
import { ReinvestYield } from './ReinvestYield';
import {
  BlockchainPoolAccountInterface,
  BlockchainPoolInterface,
  // PoolStatus,
} from '../../../store/context/states/ListState/interfaces';

import {
  CategoryTypes,
  // PoolStatusTypes,
  WithdrawalTypes,
} from '../../../constants';
import { WithdrawPermission } from './WithdrawPermission';
import { WithdrawInvestment } from './WithdrawInvestment';
import { getErrorMessage } from './helpers';

export const WithdrawYield: HelFC = (): HelElement => {
  const { appModal, dispatchAppModal } = useContext(ConfigStateContext);
  const { withdrawState: { yield: yieldValue }, dispatchWithdrawState } = useContext(WithdrawStateContext);
  const { contentTypes = [], data } = appModal;

  const { accountInvestmentSize = 0, accountPoolBalance = 0 } = data?.blockchainAccountData as BlockchainPoolAccountInterface;
  const {
    availableToWithdraw = 0,
    symbol,
  } = data?.blockchainData as BlockchainPoolInterface;
  const yieldBalance = accountPoolBalance - accountInvestmentSize;
  const availableWithdrawInvestment = accountInvestmentSize > availableToWithdraw ? availableToWithdraw : accountPoolBalance;
  // const { listState: { pools } } = useContext(ListStateContext);
  // const reinvestPools = pools?.filter((pool) => data?.id !== pool.id && pool.status === PoolStatusTypes.Active && pool?.blockchainData?.poolStatus === PoolStatus.Finalized);

  const validate = (value = 0) => {
    const isInvalid = !(value <= yieldBalance);

    if (isInvalid) {
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: {
          error: isInvalid,
          errorMessage: getErrorMessage(yieldBalance, symbol),
          disabled: isInvalid,
        },
      });
      dispatchWithdrawState({ type: WithdrawActionTypes.WithdrawBalanceUpdate, payload: { yield: value } });
    } else {
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: {
          error: value < 0,
          errorMessage: !(value >= 0) ? 'Enter amount of withdrawal' : '',
          disabled: !value,
        },
      });
      dispatchWithdrawState({ type: WithdrawActionTypes.WithdrawBalanceUpdate, payload: { yield: value } });
    }
    return true;
  };

  const handleWithdraw = () => {
    dispatchAppModal({
      type: AppModalActionTypes.ModalInfoSet,
      payload: {
        title: 'Confirm Withdrawal',
        contentTypes: [...contentTypes, ContentTypes.WithdrawYield],
        content: <WithdrawPermission type={WithdrawalTypes.Yield} />,
      },
    });
  };

  const handleReinvest = () => {
    dispatchAppModal({
      type: AppModalActionTypes.ModalInfoSet,
      payload: {
        title: 'Reinvest Yield',
        contentTypes: [...contentTypes, ContentTypes.WithdrawYield],
        content: <ReinvestYield />,
      },
    });
  };

  const handleWithdrawInvestment = () => {
    dispatchAppModal({
      type: AppModalActionTypes.ModalInfoSet,
      payload: {
        title: 'Withdraw Total Investment',
        info: {
          ...appModal.info,
          value: availableWithdrawInvestment,
        },
        contentTypes: [...contentTypes, ContentTypes.WithdrawYield],
        content: <WithdrawInvestment />,
        disabled: !availableWithdrawInvestment,
      },
    });
  };

  return (
    <>
      <p className="modal-description">
        {`You have earned ${yieldBalance} ${symbol}. You can either withdraw it or reinvest your profit into another pool. Reinvesting will allow you to fund more solar and earn more yield.  Both you and the earth win!`}
      </p>
      <div className="withdraw-yield modal-content">
        <div className="yield-input-container">
          <div className="modal-input">
            <NumericFormat
              className={`${appModal.error ? 'error' : ''} input`}
              defaultValue={yieldValue}
              value={yieldValue}
              isAllowed={(values) => {
                const { floatValue = 0 } = values;
                return validate(floatValue);
              }}
              placeholder="Yield amount"
              suffix={` ${symbol}`}
              thousandSeparator
            />
            {appModal.errorMessage && <p className="error-message">{appModal.errorMessage}</p>}
          </div>
        </div>
        <ActionButtons
          items={[
            {
              category: CategoryTypes.Filled,
              label: 'Reinvest',
              callBack: handleReinvest,
              disabled: appModal.disabled, // || !reinvestPools?.length,
            },
            {
              category: CategoryTypes.Outlined,
              label: 'Withdraw',
              callBack: handleWithdraw,
              disabled: !yieldValue || appModal.error, // appModal.disabled,
            },
          ]}
        />
        <button className="withdraw-investment" onClick={handleWithdrawInvestment}><p>Withdraw your full investment from Helios?</p></button>
      </div>
    </>
  );
};
