import { useContext, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Link, useSearchParams } from 'react-router-dom';
import { HelElement, HelFC } from '../../interfaces';
import { CategoryTypes } from '../../constants';
import { ButtonBase, Spinner } from '../../components';
import { AppModalActionTypes, ConfigStateContext, TransactionStateContext } from '../../store';
import connectedIcon from '../../assets/images/connected-icon.svg';
import shevronDownIcon from '../../assets/images/chevron-down.svg';
import copyIcon from '../../assets/images/copy-icon.svg';
import linkIcon from '../../assets/images/eth-link.svg';
import disconnectIcon from '../../assets/images/disconnect-icon.svg';
import { useConnectWallet, useCopyToClipboard } from '../../services';
import { BlockchainDataHandler } from '../../store/DataHandlers/BlockchainDataHandler';
import { getNetwork } from '../../utils';

export const Header: HelFC = ({ children }): HelElement => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [balance, setBalance] = useState({ value: 0, symbol: '' });
  const { transactionState: { transactions } } = useContext(TransactionStateContext);
  const NET = getNetwork(process.env.REACT_APP_CHAIN_ID!);
  const { dispatchAppModal } = useContext(ConfigStateContext);
  const {
    account, deactivate, chainId,
  } = useWeb3React();
  const { getAccountBalance } = BlockchainDataHandler();
  const { connectWallet } = useConnectWallet();

  const [searchParams, setSearchParams] = useSearchParams();

  let address = '';
  if (account) {
    address = `${account?.substr(0, 6)}...${account?.substr(-4)}`;
  }
  const [copy] = useCopyToClipboard();
  const [copiedOpen, setCopiedOpen] = useState(false);

  useEffect(() => {
    window.ethereum.on('chainChanged', (id: string) => {
      if (Number(id).toString() !== process.env.REACT_APP_CHAIN_ID!) {
        deactivate();
        setMenuIsOpen(false);
        searchParams.delete('isConnected');
        setSearchParams(searchParams);
        localStorage.setItem('isWalletConnected', 'false');
      }
    });
  }, [chainId]);

  const handleConnectClick = async () => {
    try {
      const id = await window.ethereum.request({
        method: 'eth_chainId',
      });
      if (id && (Number(id)?.toString() !== process.env.REACT_APP_CHAIN_ID!)) {
        dispatchAppModal({
          type: AppModalActionTypes.MessageModal,
          payload: {
            cancelButtonText: 'OK',
            title: 'Network error',
            description: `Please select ${NET} network.`,
          },
        });
      } else {
        await connectWallet();
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleCopyClick = async () => {
    await copy(account as string).then((result) => {
      if (result) {
        setCopiedOpen(true);
        setTimeout(() => setCopiedOpen(false), 1000);
      }
    });
  };

  useEffect(() => {
    if (getAccountBalance && account) {
      getAccountBalance().then((res) => {
        setBalance(res);
      }).catch((e) => { console.log(e); });
    }
  }, [account]);

  useEffect(() => {
    if (menuIsOpen) {
      document.getElementById('account-menu')?.focus();
    }
  }, [menuIsOpen]);

  const disconnect = async () => {
    try {
      deactivate();
      setMenuIsOpen(false);
      searchParams.delete('isConnected');
      setSearchParams(searchParams);

      localStorage.setItem('isWalletConnected', 'false');
    } catch (ex) {
      console.log(ex);
    }
  };

  const handleDropdownClick = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  return (
    <div className={`header ${account ? 'connected' : ''} `}>
      <Link to="/pools">
        <div className="header-logo" />
      </Link>
      <div className="spinner-block">
        {
          !!transactions.length
          && transactions.map((transaction) => (<Spinner text={transaction.description} color="#DC5A5A" />))
          }
      </div>
      {!account ? (
        <div className="links-block">
          <ButtonBase
            className="button-without-icon"
            label="Connect Wallet"
            category={CategoryTypes.Filled}
            onClick={handleConnectClick}
          />
        </div>
      ) : (
        <div className="links-block">
          <div
            className="account-dropdown"
          >
            <ButtonBase
              className="button-with-icon button-connected"
              category={CategoryTypes.Default}
              onClick={handleDropdownClick}
            >
              <img src={connectedIcon} alt="connected" />
              <span className="label">
                {address}
              </span>
              <img src={shevronDownIcon} alt="downIcon" />
            </ButtonBase>
            {menuIsOpen && (
            <div
              id="account-menu"
              className="account-menu button-connected"
              tabIndex={0}
              onBlur={(event) => {
                if (!event.currentTarget.contains(event.relatedTarget)) {
                  setMenuIsOpen(false);
                }
              }}
            >
              <div className="copy-address">
                <img src={connectedIcon} alt="connected" />
                <span className="label">
                  {address}
                </span>
                <button onClick={handleCopyClick}><img src={copyIcon} alt="copy" /></button>
                {copiedOpen && <div className="copy-popup">copied!</div>}
              </div>
              <div className="balance">
                <h3>{balance.value}</h3>
                {balance.symbol || ''}
              </div>
              <hr />
              <a href={`${process.env.REACT_APP_EXPLORER_BASE_LINK}/${process.env.REACT_APP_EXPLORER_NET_PATH}/address/${account}`} target="_blank" rel="noreferrer">
                <ButtonBase
                  className="button-with-icon"
                  category={CategoryTypes.Default}
                >
                  <img src={linkIcon} alt="" />
                  <span className="label">
                    Explore
                  </span>
                </ButtonBase>
              </a>
              <ButtonBase
                className="button-with-icon"
                category={CategoryTypes.Default}
                onClick={disconnect}
              >
                <img src={disconnectIcon} alt="" />
                <span className="label">
                  Disconnect
                </span>
              </ButtonBase>
            </div>
            )}
          </div>
        </div>
      )}
      {children}
    </div>
  );
};
