import { useContext } from 'react';
import { ModalWrapper } from '../../modals';
import { ConfigStateContext } from '../../../store';
import { HelFC } from '../../../interfaces';

export const AppModal:HelFC = () => {
  const { appModal } = useContext(ConfigStateContext);

  if (!appModal?.isOpen) {
    return null;
  }
  return (
    <div className="app-modal">
      <ModalWrapper />
    </div>
  );
};
