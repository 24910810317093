import { AppModalStateInterface } from './interfaces';
import { PoolInterface } from '../../ListState/interfaces';

export const appModalInitialState: AppModalStateInterface = {
  isOpen: false,
  error: false,
  errorMessage: '',
  disabled: false,
  isClosable: true,
  contentTypes: [],
  data: {} as PoolInterface,
};
