import { ContentTypes } from './interface';
import { WithdrawYield } from './WithdrawYield';
import { ReinvestYield } from './ReinvestYield';
import { WithdrawInvestment } from './WithdrawInvestment';
import { DepositFunds } from './DepositFunds';

export const ContentTypesObject = {
  [ContentTypes.WithdrawYield]: {
    title: 'Withdraw Yield',
    content: <WithdrawYield />,
  },
  [ContentTypes.WithdrawInvestment]: {
    title: 'Withdraw Total Investment',
    content: <WithdrawInvestment />,
  },
  [ContentTypes.ReinvestYield]: {
    title: 'Reinvest Yield',
    content: <ReinvestYield />,
  },

  [ContentTypes.DepositFunds]: {
    title: 'Deposit Funds',
    content: <DepositFunds />,
  },
};
