import { AppRoutes, HeliosAppRoutes } from '../modules/init/AppRoutes';

export const APPS = [
  {
    subdomain: 'www',
    app: AppRoutes,
    main: true,
  },
  {
    subdomain: 'test',
    app: AppRoutes,
    main: true,
  },
  {
    subdomain: 'app',
    app: HeliosAppRoutes,
    main: false,
  },
];

export const getApp = () => {
  const locationParts = window.location.hostname.split('.');

  const sliceTill = 1 - locationParts.length;

  const subdomain = locationParts.slice(0, sliceTill).join('');

  const main = APPS.find((app) => app.main);
  if (!main) throw new Error('Must have main app');

  if (subdomain === '') return { app: main.app, subdomain };

  const appType = APPS.find((app) => subdomain === app.subdomain);

  if (!appType) return { app: main.app, subdomain };
  return { app: appType.app, subdomain };
};

export const getAppUrl = () => window.location.origin.replace(window.location.host, `app.${window.location.host}`);
