import { useEffect } from 'react';
import { CategoryTypes } from '../../constants';
import { HelElement, HelFC } from '../../interfaces';
import { ButtonBase } from '../../components';
import { PageTitleBoxProps } from './interfaces';

import { getAppUrl } from '../../utils';

export const PageTitleBox: HelFC<PageTitleBoxProps> = ({
  logo, withActions = false, title, description, video,
}): HelElement => {
  const appUrl = getAppUrl();

  useEffect(() => {
    if (video) {
      const videoTag = document.getElementById('video') as HTMLMediaElement;
      if (videoTag) {
        videoTag.play().catch();
      }
    }
  });

  return (
    <div className="page-title">
      <div className="info-block">
        <h1>
          {title}
        </h1>
        <p className="description">{description}</p>
        {
        withActions && (
          <div className="action-buttons">
            <a
              href={`${appUrl}/pools`}
              target="_blank"
              rel="noreferrer"
            >
              <ButtonBase
                className="button-without-icon"
                label="View Pools"
                category={CategoryTypes.Filled}
              />
            </a>
            <ButtonBase className="button-without-icon" label="Learn More" category={CategoryTypes.Outlined} />
          </div>
        )
      }
      </div>
      {!!logo && <img src={logo} alt="earth" />}
      {!!video && (
      <video src={video} autoPlay muted loop playsInline id="video" preload="none" />
      )}
    </div>
  );
};
