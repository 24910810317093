import { HelElement, HelFC } from '../../interfaces';
import { IconPropsInterface } from './interfaces';

export const IconEmission: HelFC<IconPropsInterface> = ({ width, height, className }): HelElement => (
  <svg
    className={`${className || 'icon-primary'}`}
    id="svg-icon-emission"
    width={width}
    height={height}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40.6471 29.4711C42.5303 29.4711 44.2473 30.1745 45.5587 31.3337C47.1254 30.1789 49.0583 29.496 51.1502 29.496C55.3397 29.496 58.8918 32.232
       60.1376 36.0231C61.2827 35.5381 62.5416 35.2699 63.8618 35.2699C69.1615 35.2699 73.4575 39.5856 73.4575 44.9095C73.4575 45.1601 73.4444 45.4063
       73.4269 45.6511C74.1373 45.9676 74.8098 46.3545 75.4385 46.8C77.7229 44.7967 79.17 41.8526 79.17 38.5671C79.17 32.5324 74.3007 27.6408 68.2935
        27.6408C68.1359 27.6408 67.9784 27.6467 67.8223 27.6525C66.435 25.7284 64.1812 24.474 61.6357 24.474C60.9968 24.474 60.3783 24.5546 59.7845
        24.7026C58.6628 21.9446 55.967 20 52.819 20C49.671 20 47.2407 21.7556 46.0299 24.2981C45.194 24.0505 44.3115 23.9142 43.3954 23.9142C38.2722
        23.9142 34.1177 28.0863 34.1177 33.2344C34.1177 33.274 34.1206 33.3135 34.1206 33.3531C35.3853 31.0377 37.8346 29.4697 40.6485 29.4697L40.6471 29.4711Z"
      stroke="#4F3EFF"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M80.3772 56.3737C80.3772 51.5861 77.5195 47.4712 73.4262 45.6511C73.4452 45.4064 73.4569 45.1587 73.4569 44.9096C73.4569 39.5857 69.1608 35.2699
       63.8611 35.2699C62.541 35.2699 61.2821 35.5381 60.1369 36.0232C58.8912 32.2335 55.3405 29.4961 51.1495 29.4961C49.0577 29.4961 47.1248 30.179 45.5581
        31.3338C44.2481 30.1761 42.5297 29.4712 40.6464 29.4712C37.4328 29.4712 34.6947 31.5184 33.6532 34.3848C33.0332 34.25 32.3899 34.1767 31.7291 34.1767C26.9779
         34.1767 23.0844 37.8594 22.7183 42.5385C18.1611 44.6707 15 49.3118 15 54.6943C15 58.1498 16.3027 61.3005 18.4412 63.6775C18.5579 69.2374 23.0786 73.7084
         28.6409 73.7084C30.5124 73.7084 32.2644 73.1999 33.7713 72.3177C35.3555 73.9326 37.5553 74.935 39.9915 74.935C41.9608 74.935 43.7784 74.2799 45.2386
         73.1735C46.7878 75.0552 49.1291 76.2539 51.7491 76.2539C54.369 76.2539 56.6388 75.0903 58.188 73.2571C59.8248 74.321 61.7751 74.9408 63.8699 74.9408C68.8472
         74.9408 73.0105 71.4531 74.0783 66.7798C77.82 64.8264 80.3772 60.9005 80.3772 56.3723V56.3737Z"
      stroke="#4F3EFF"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.0776 50.9146L45.6581 57.5252L58.3099 44.8154"
      stroke="#4F3EFF"
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

IconEmission.defaultProps = {
  className: '',
  width: '96px',
  height: '96px',
};
