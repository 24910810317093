import { HelFC } from '../../../interfaces';
import { AppPreloader, Preloader } from '../AppPreloader';
import { getApp } from '../../../utils';
import { MainContainer } from './MainContainer';

export const AppContainer:HelFC = () => {
  const { app, subdomain } = getApp();
  if (subdomain === 'app') {
    return <MainContainer preloader={AppPreloader} app={app} />;
  }
  return <MainContainer preloader={Preloader} app={app} />;
};
