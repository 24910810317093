import { ListInterface } from './interfaces';
import { listInitialState } from './initialState';

const localStateName = 'helios_lists';

export const getLocalLists = (): ListInterface => {
  const localLists = localStorage.getItem(localStateName) ? JSON.parse(
    localStorage.getItem(localStateName) || '',
  ) : null;
  return localLists || { ...listInitialState };
};

export const setLocalLists = (
  state: ListInterface,
): ListInterface => {
  localStorage.setItem(localStateName, JSON.stringify(state));
  return state;
};
