import { HelElement, HelFC } from '../../interfaces';
import { TestimonialCardProps } from './interfaces';

export const TestimonialCard: HelFC<TestimonialCardProps> = ({
  image, title, jobTitle, description, className,
}): HelElement => (
  <div className={`testimonial-card ${className || ''}`}>
    <div className="description"><p>{description}</p></div>
    <div className="testimonial-info">
      <div className="image">{image ? <img src={image} alt="testimonial 1" /> : <div className="skeleton" />}</div>
      <div className="title">
        <h5>{title}</h5>
        {!!jobTitle && <span>{jobTitle}</span>}
      </div>
    </div>
  </div>
);
