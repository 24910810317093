import { AccountInterface } from './interfaces';
import { accountInitialState } from './initialState';

const localStateName = 'helios_account';

export const getLocalAccount = (): AccountInterface => {
  const localAccount = localStorage.getItem(localStateName) ? JSON.parse(
    localStorage.getItem(localStateName) || '',
  ) as AccountInterface : accountInitialState;
  return { ...localAccount };
};

export const setLocalAccount = (
  state: AccountInterface,
): AccountInterface => {
  localStorage.setItem(localStateName, JSON.stringify(state));
  return { ...state };
};
