import { useWeb3React } from '@web3-react/core';
import { useContext } from 'react';
import { injected } from './connectors';
import { AppModalActionTypes, ConfigStateContext } from '../../store';
import { getNetwork } from '../../utils';

export const useConnectWallet = () => {
  const {
    activate, account, active, deactivate, chainId,
  } = useWeb3React();

  const NET = getNetwork(process.env.REACT_APP_CHAIN_ID!);
  const { dispatchAppModal } = useContext(ConfigStateContext);

  // const isMobile = useMediaQuery('(max-width: 600px)');

  const connectWallet = async () => {
    // if (isMobile) {
    //   window.location.href = 'https://metamask.app.link/dapp/app.test.helios.eco/';
    // } else {
    // const provider = new ethers.providers.JsonRpcProvider();
    // const id = await provider.getNetwork();

    try {
      const id = await window.ethereum.request({
        method: 'eth_chainId',
      });
      if (id && (Number(id)?.toString() !== process.env.REACT_APP_CHAIN_ID!)) {
        dispatchAppModal({
          type: AppModalActionTypes.MessageModal,
          payload: {
            cancelButtonText: 'OK',
            title: 'Network error',
            description: `Please select ${NET} network.`,
          },
        });
      } else {
        await activate(injected);
        localStorage.setItem('isWalletConnected', 'true');
        dispatchAppModal({ type: AppModalActionTypes.Close });
      }
    } catch (e) {
      console.log(e);
    }
    // }
  };

  return {
    connectWallet, account, active, deactivate, chainId,
  };
};
