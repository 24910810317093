import { useContext } from 'react';
import { ConfigStateContext } from '../../../store';
import { HelFC } from '../../../interfaces';
import { IconLoaderPrimary } from '../../../components';

export const AppLoader:HelFC = () => {
  const { appLoader } = useContext(ConfigStateContext);

  if (appLoader.isOpen) {
    return (
      <div className="app-loader">
        <div className="loader-wrapper">
          {!!appLoader.description && <h4>{ appLoader.description }</h4>}
          <IconLoaderPrimary />
        </div>
      </div>
    );
  }

  return null;
};
