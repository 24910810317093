import { HelElement, HelFC } from '../../interfaces';
import { ReinvestPoolCardProps } from './interfaces';
import {
  PoolDetailBlock, ProgressBlock,
} from '../../components';
import infoIcon from '../../assets/images/info.svg';

export const ReinvestCard: HelFC<ReinvestPoolCardProps> = ({
  pool, onClick, className, active,
}): HelElement => {
  const {
    poolSize = 0, apy, totalDeposited, symbol,
  } = pool.blockchainData || {};
  const { name, images, annualEmissionsAverted } = pool;

  return (
    <div className={`reinvest-pool-card ${className || ''} ${active ? 'active' : ''}`} onClick={onClick}>
      <div className="pool-name">
        {images?.length && <img src={images[0].urls.small || ''} alt="pool 1" />}
        <h4>{name}</h4>
      </div>
      <PoolDetailBlock value={apy || 0} label="APY" suffix="%" />
      <PoolDetailBlock value={annualEmissionsAverted || 0} label="Emissions Averted" suffix="kg" />
      <ProgressBlock
        className="reinvest-pool-progress"
        label="Pool Size:"
        value={poolSize}
        currentValue={totalDeposited}
        suffix={symbol}
      />
      <div className="info">
        <img src={infoIcon} alt="" />
      </div>
    </div>
  );
};
