import { ButtonBaseProps } from './interfaces';
import {
  ButtonTypes, ColorTypes,
} from '../constants';
import { HelElement, HelFC } from '../interfaces';

export const ButtonBase: HelFC<ButtonBaseProps> = (props): HelElement => {
  const {
    color,
    category,
    className,
    size,
    label,
    type,
    onClick,
    onBlur,
    disabled = false,
    children,
  } = props;

  return (
    <button
      className={[
        size,
        `button-${category}-${color}`,
        className,
      ].join(' ')}
      type={type}
      onClick={onClick}
      onBlur={onBlur}
      disabled={disabled}
    >
      { children }
      {label || ''}
    </button>
  );
};

ButtonBase.defaultProps = {
  color: ColorTypes.Primary,
  type: ButtonTypes.Button,
};
