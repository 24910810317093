import { createContext, useMemo, useReducer } from 'react';
import {
  PlatformInterface,
  PlatformStateContextInterface,
  PlatformActionType,
} from './interfaces';
import { platformInitialState } from './initialState';
import { platformStateReducer } from './reducer';
import { ContextProviderPropsInterface, UseReducerType } from '../../../interfaces';
import { HelElement, HelFC } from '../../../../interfaces';

export const PlatformStateContext = createContext<PlatformStateContextInterface>(
  {} as PlatformStateContextInterface,
);

export const PlatformStateProvider
:HelFC<ContextProviderPropsInterface> = ({ children }): HelElement => {
  const [
    platformState,
    dispatchPlatformState,
  ] = useReducer<
  UseReducerType<PlatformInterface, PlatformActionType>
  >(platformStateReducer, platformInitialState);

  const value = useMemo(() => ({
    platformState,
    dispatchPlatformState,
  }), [platformState]);

  return (
    <PlatformStateContext.Provider
      value={value}
    >
      {children}
    </PlatformStateContext.Provider>
  );
};
