import { HelElement, HelFC } from '../../interfaces';
import { ImpactInfoBoxProps } from './interfaces';
import { ImpactInfoBlock } from '../../components';
import { DirectionTypes } from '../../constants';

export const ImpactInfoBox:HelFC<ImpactInfoBoxProps> = ({ items, direction }): HelElement => (
  <div className={`impact-info-box ${direction}`}>
    {items.map(({ value, title, icon }, index) => (
      <div key={`impact-info-${index}`}>
        <ImpactInfoBlock value={value} title={title} icon={icon} />
      </div>
    ))}
  </div>
);

ImpactInfoBox.defaultProps = {
  direction: DirectionTypes.Horizontal,
};
