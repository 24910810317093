import { useContext, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import {
  PoolCardBox,
  PageTitleBox,
  InvestmentCardBox,
  ChartBox, AccountImpactInfoBox,
} from '../modules';
import { HelElement, HelFC } from '../interfaces';
import poolLogo from '../assets/images/Pool.png';
import { ListStateContext } from '../store';
import {
  PoolStatusTypes, REGIONS, RegionTypes,
} from '../constants';
import howItWorksGradient from '../assets/images/gradients/HowItWorks.png';
import { ChartCardProps } from '../modules/cards/interfaces';
import { numberWithCommas } from './helpers';

export const HeliosPools: HelFC = (): HelElement => {
  const { account } = useWeb3React();
  const [activeRegion, setActiveRegion] = useState<string>(RegionTypes.All);

  const { listState: { pools = [] } } = useContext(ListStateContext);
  // TODO: temporary filtering - this will be filtered only by Active statuses in the future
  const activePools = pools?.filter((pool) => pool.status === PoolStatusTypes.Active && pool.blockchainData?.poolStatus === 'Finalized') || [];
  const closedPools = pools?.filter((pool) => pool.status === PoolStatusTypes.Closed) || [];
  const investedPools = activePools?.filter((pool) => !!pool.blockchainAccountData?.accountInvestmentSize) || [];
  const notInvestedPools = activePools?.filter((pool) => !pool.blockchainAccountData?.accountInvestmentSize) || [];

  const cardPools = notInvestedPools.length ? [...investedPools, notInvestedPools[0]] : investedPools;
  const currentPools = activeRegion !== RegionTypes.All ? activePools.filter((pool) => pool.region === activeRegion) : activePools;

  let accountTotalInvestedAmount = 0;
  let sumOfAPY = 0;
  let totalEmissionsAverted = 0;
  investedPools.forEach(({ blockchainAccountData, blockchainData, annualEmissionsAverted }) => {
    accountTotalInvestedAmount += (blockchainAccountData?.accountInvestmentSize || 0);
    sumOfAPY += (blockchainData?.apy || 0);
    totalEmissionsAverted += annualEmissionsAverted || 0;
  });
  const investmentSymbol = activePools[0].blockchainData?.symbol || '';
  const charts: ChartCardProps[] = [{
    chart: {
      title: 'Yield',
      info: [
        { label: 'Total Invested', value: `${numberWithCommas(accountTotalInvestedAmount)} ${investmentSymbol}` },
        { label: 'Total Earnings', value: '$420' }, // TODO: we have no history store for earning amounts, need to add in the future
        { label: 'APY', value: `${sumOfAPY / investedPools.length} %` },
      ],
      data: [3000, 8000, 9000, 6000, 9000, 10000, 7000, 6000],
    },
  }, {
    chart: {
      title: 'Impact',
      info: [{ label: 'Emissions Averted', value: `${numberWithCommas(totalEmissionsAverted)} kg` }],
      data: [3000, 8000, 9000, 6000, 9000, 10000, 7000, 6000],
    },
  }];

  const accountImpactInfoList = [{
    value: 4000,
    title: 'car',
  },
  {
    value: 6000,
    title: 'planes',
  },
  {
    value: 500,
    title: 'households',
  },

  ]; // TODO: need to get from the backend

  const handleRegionClick = (region: string) => () => {
    setActiveRegion(region);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    const accountImpacts = document.getElementById('account-impact-info');

    window.addEventListener('scroll', () => {
      const value = window.scrollY;
      if (accountImpacts) {
        accountImpacts.style.top = `${(700 + value * 0.25)}px`;
      }
    });
  }, []);

  return (
    <>
      {!account
        ? (
          <PageTitleBox
            title="Invest Alongside Climate Experts"
            description="We source our solar projects from top developers around the world, reducing risk while maximizing impact and return."
            logo={poolLogo}
          />
        ) : (
          <>
            {investedPools.length
              ? (
                <>
                  <div className="charts">
                    <div className="title-description">
                      <h2>Welcome</h2>
                    </div>
                    <ChartBox charts={charts} />
                  </div>
                  <div className="investments">
                    <div className="title-description">
                      <h2>Your Investments</h2>
                    </div>
                    <InvestmentCardBox pools={cardPools} />
                  </div>
                </>
              )
              : (
                <div className="investments">
                  <div className="title-description">
                    <h2>Welcome</h2>
                  </div>
                  <div className="empty-investments">You have no investments yet</div>
                </div>
              )}
          </>
        )}

      <div className="sub-content account-impact-info">
        <img id="account-impact-info" className="gradient" src={howItWorksGradient} alt="" data-modifier="100" />
        <div className="title-description">
          <h2>My Impact</h2>
          <p className="description">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus et tincidunt rutrum enim volutpat, feugiat amet, vitae. Augue ac faucibus odio amet, amet odio accumsan, nunc ridiculus.
            {' '}
          </p>
        </div>
        <AccountImpactInfoBox
          items={accountImpactInfoList}
        />
      </div>
      <div className="available-pools">
        {!!currentPools.length && <img id="work-flow" className="gradient" src={howItWorksGradient} alt="" data-modifier="100" /> }
        <div className="title-description">
          <h2>Available pools</h2>
          <p className="description">
            Projects are grouped geographically, ensuring your capital flows where it’s needed most. You can easily build a diversified portfolio by funding multiple pools spanning the entire globe.
          </p>
        </div>
        <div className="region-filter">
          {REGIONS.map((region, index) => (
            <div key={index}><button className={`${activeRegion === region ? 'active-region' : ''}`} onClick={handleRegionClick(region)}>{region}</button></div>
          ))}
        </div>
        <PoolCardBox pools={activeRegion !== RegionTypes.All ? activePools.filter((pool) => pool.region === activeRegion) : activePools} />
      </div>
      {!!closedPools.length && (
        <div className="closed-pools">
          <div className="title-description">
            <h2>Closed pools</h2>
            <p className="description">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Purus et tincidunt rutrum enim volutpat,
              feugiat
              amet, vitae. Augue ac faucibus odio amet, amet odio accumsan, nunc ridiculus.
            </p>
          </div>
          <PoolCardBox pools={closedPools} />
        </div>
      )}
    </>
  );
};
