import { HelElement, HelFC } from '../../interfaces';
import { PoolDetailProps } from '../../modules/cards/interfaces';
import { numberWithCommas } from '../../pages/helpers';

export const PoolBalanceProgressBlock: HelFC<PoolDetailProps> = ({
  className, value = 0, currentValue = 0, suffix,
}): HelElement => {
  const currentValuePercent = ((currentValue as number) * 100) / (value as number);

  return (
    <div className={`pool-progress-block ${className || ''}`}>
      <div className="progress-info">
        <span className="current">
          {`${numberWithCommas(currentValue as number)} ${suffix || ''}`}
        </span>
        <span className="total">
          {`of ${numberWithCommas(value as number)} ${suffix || ''}`}
        </span>
      </div>
      <div className="max-value-progress">
        <div className="current-value-progress" style={{ width: `${currentValuePercent}%` }} />
      </div>
    </div>
  );
};
