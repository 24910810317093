import { useContext } from 'react';
import { BigNumber, ethers } from 'ethers';
import { useWeb3React } from '@web3-react/core';

import {
  BlockchainDataHandlerInterface,
  DepositValues,
  ParsedTransactionInterface,
  PoolBlockchainAccountValues,
  PoolBlockchainValues,
  WithdrawalValues,
} from './interfaces';
import abiPoolFactory from '../../../contracts/abi/PoolFactory.json';
import abiIERC20Factory from '../../../contracts/abi/IERC20.json';
import abiIERC20MetaDataFactory from '../../../contracts/abi/IERC20Metadata.json';
import abiPool from '../../../contracts/abi/Pool.json';
import { PoolStatus } from '../../context/states/ListState/interfaces';
import { Address, RequestTypes, WithdrawalTypes } from '../../../constants';
import {
  TransactionActionTypes,
  TransactionStateContext,
} from '../../context/states/TransactionState';
import { transformNumber } from '../../../utils';
import { httpRequest } from '../../../services';

export const BlockchainDataHandler = (): BlockchainDataHandlerInterface => {
  const { active, account, library } = useWeb3React();
  const { dispatchTransactionState } = useContext(TransactionStateContext);

  const CONTRACT_POOL_FACTORY = process.env.REACT_APP_CONTRACT_POOL_FACTORY!;
  const CONTRACT_USDC = process.env.REACT_APP_CONTRACT_USDC!;

  const provider = new ethers.providers.JsonRpcProvider(process.env.REACT_APP_CHAIN_NETWORK_URL);
  const poolFactory = new ethers.Contract(CONTRACT_POOL_FACTORY, abiPoolFactory, provider);
  const IERC20MetaData = new ethers.Contract(CONTRACT_USDC, abiIERC20MetaDataFactory, provider);

  const getAccountBalance = async (): Promise<{ value: number, symbol: string }> => {
    const signer = library.getSigner();

    const decimals = await IERC20MetaData.decimals();
    const balance = await IERC20MetaData.balanceOf(signer.getAddress());
    const symbol = await IERC20MetaData.symbol();

    const accountBalance = transformNumber(balance, decimals, true);

    return {
      value: Number(parseFloat(`${accountBalance}`).toFixed(2)),
      symbol,
    };
  };

  const getPool = async (poolId: string): Promise<PoolBlockchainValues> => {
    try {
      if (poolId) {
        const address = await poolFactory.pools(poolId);
        if (address === Address.Null) {
          return {
            poolStatus: PoolStatus.NotExist,
          } as PoolBlockchainValues;
        }
        // Create Pool contract object
        const pool = new ethers.Contract(address, abiPool, provider);
        if (!pool) {
          return {
            poolStatus: PoolStatus.NotExist,
          } as PoolBlockchainValues;
        }

        const [
          poolInfo,
          decimals,
          poolState,
          borrower,
          symbol,
          totalMinted,
          availableWithdrawAmount,
        ] = await Promise.all([
          pool.poolInfo(),
          pool.decimals(),
          pool.poolState(),
          pool.borrower(),
          IERC20MetaData.symbol(),
          pool.totalDeposited(),
          pool.availableToWithdraw(),
        ]);

        let poolStatus: PoolStatus;
        const {
          apy, lockupPeriod, investmentPoolSize, minInvestmentAmount, duration,
        } = poolInfo;

        switch (poolState) {
          case 0:
            poolStatus = PoolStatus.Initialized;
            break;
          case 1:
            poolStatus = PoolStatus.Finalized;
            break;
          case 2:
            poolStatus = PoolStatus.Terminated;
            break;
          default:
            poolStatus = PoolStatus.Error;
            break;
        }

        const poolSize = transformNumber(investmentPoolSize, decimals, true);
        const minInvestmentSize = transformNumber(minInvestmentAmount, decimals, true);
        const totalDeposited = transformNumber(totalMinted, decimals, true);
        const availableToWithdraw = transformNumber(availableWithdrawAmount, decimals, true);

        return {
          poolId,
          poolAddress: address,
          apy: apy.toNumber(),
          poolSize,
          minInvestmentSize,
          duration: duration.toNumber(),
          lockupPeriod: (lockupPeriod.toNumber()) / 86400,
          totalDeposited,
          availableToWithdraw,
          borrower,
          symbol,
          poolStatus,
        };
      }

      return {
        poolStatus: PoolStatus.NotExist,
      } as PoolBlockchainValues;
    } catch (error) {
      console.log('Pool data fetching Error:', error);
      return {
        poolStatus: PoolStatus.Error,
      } as PoolBlockchainValues;
    }
  };

  const getAccountPool = async (poolId: string): Promise<PoolBlockchainAccountValues> => {
    try {
      if (poolId && account) {
        const signer = library.getSigner();
        const address = await poolFactory.pools(poolId);
        if (address === Address.Null) {
          return {} as PoolBlockchainValues;
        }
        // Create Pool contract object
        const pool = new ethers.Contract(address, abiPool, signer);
        if (!pool) {
          return {} as PoolBlockchainValues;
        }

        const [
          decimals,
          accountInvestmentSize,
          withdrawableFunds,
          depositDate,
        ] = await Promise.all([
          pool.decimals(),
          pool.balanceOf(account),
          pool.withdrawableFundsOf(account),
          pool.depositDate(account),
        ]);

        const investmentSize = transformNumber(accountInvestmentSize, decimals, true);
        const funds = transformNumber(withdrawableFunds, decimals, true);

        return {
          accountInvestmentSize: investmentSize,
          accountPoolBalance: Math.floor(investmentSize + funds),
          depositDate: depositDate.toNumber(),
        };
      }

      return {} as PoolBlockchainAccountValues;
    } catch (error) {
      console.log('Pool data fetching Error:', error);
      return {} as PoolBlockchainAccountValues;
    }
  };

  const getRepaymentList = async (poolAddress: string): Promise<ParsedTransactionInterface[]> => {
    try {
      if (account) {
        const abiInterface = new ethers.utils.Interface(abiPool);
        const url = `https://api-alfajores.celoscan.io/api?module=account&action=txlist&address=${poolAddress}`;

        const response = await httpRequest({ url, method: RequestTypes.Get, withoutToken: true });
        // Take the first 10 characters of the hash as the method ID
        const methodId = ethers.utils.id('makePayment(uint256)').slice(0, 10);

        const decimals = await IERC20MetaData.decimals();
        const symbol = await IERC20MetaData.symbol();

        const paymentHistory = response?.data.result.filter((tx: { methodId: string; }) => tx.methodId === methodId);
        const repaymentHistory = paymentHistory.map((tx: any) => {
          const txValue = abiInterface.decodeFunctionData('makePayment', tx.input);
          return {
            txHash: tx.hash,
            txDate: new Date(Number(tx.timeStamp || 0) * 1000),
            method: 'makePayment',
            value: Number(txValue[0].toString()) * (10 ** (-(decimals || 0))),
            from: tx.from,
            symbol,
          };
        });
        return repaymentHistory || [];
      }
      return [];
    } catch (error) {
      console.log('Transaction history data fetching Error:', error);
      return [];
    }
  };

  const deposit = async (poolId: string, amount: number): Promise<DepositValues> => {
    try {
      if (poolId && active) {
        const signer = library.getSigner();
        const poolAddress = await poolFactory.pools(poolId);
        const IERC20WithSigner = new ethers.Contract(CONTRACT_USDC, abiIERC20Factory, signer);

        if (poolAddress === Address.Null) {
          return {
            deposited: false,
            isPool: false,
          };
        }
        // Create Pool contract object
        const pool = new ethers.Contract(poolAddress, abiPool, signer);
        if (!pool) {
          return {
            isPool: false,
            deposited: false,
          };
        }
        const decimals = await pool.decimals();

        let amountWad: BigNumber | number;

        if (decimals > 6) {
          const dec = BigNumber.from(10);
          const bigAmount = BigNumber.from(amount * 10 ** 6);
          amountWad = bigAmount.mul(dec.pow(decimals - 6));
        } else {
          amountWad = amount * 10 ** decimals;
        }

        const approveTx = await IERC20WithSigner.approve(pool.address, amountWad);

        dispatchTransactionState({
          type: TransactionActionTypes.SetTransaction,
          payload: {
            hash: approveTx.hash, description: 'Approving',
          },
        });

        const approveReceipt = await approveTx.wait();
        dispatchTransactionState({
          type: TransactionActionTypes.DeleteTransaction,
          payload: {
            hash: approveTx.hash,
          },
        });
        if (approveReceipt.status === 1) {
          const depositTx = await pool.deposit(amountWad);
          dispatchTransactionState({
            type: TransactionActionTypes.SetTransaction,
            payload: {
              hash: depositTx.hash, description: 'Depositing',
            },
          });

          const depositReceipt = await depositTx.wait();
          dispatchTransactionState({
            type: TransactionActionTypes.DeleteTransaction,
            payload: { hash: depositTx.hash },
          });
          if (depositReceipt.status === 1) {
            return {
              deposited: true,
            };
          }
        }
      }

      return {
        isPool: false,
        deposited: false,
      };
    } catch (error) {
      console.log('Deposit fail error:', error);
      return {
        deposited: false,
      };
    }
  };

  const withdraw = async (poolId: string, amount: number, type: WithdrawalTypes): Promise<WithdrawalValues> => {
    try {
      if (poolId && active) {
        const signer = library.getSigner();
        const poolAddress = await poolFactory.pools(poolId);

        if (poolAddress === Address.Null) {
          return {
            withdrawn: false,
            isPool: false,
          };
        }
        // Create Pool contract object
        const pool = new ethers.Contract(poolAddress, abiPool, signer);
        if (!pool) {
          return {
            isPool: false,
            withdrawn: false,
          };
        }
        const decimals = await pool.decimals();
        let amountWad: BigNumber | number = 0;
        if (amount) {
          if (decimals > 6) {
            const dec = BigNumber.from(10);
            const bigAmount = BigNumber.from(+amount * 10 ** 6);
            amountWad = bigAmount.mul(dec.pow(+decimals - 6));
          } else {
            amountWad = +amount * 10 ** decimals;
          }
        }

        if (amountWad) {
          switch (type) {
            case WithdrawalTypes.Investment: {
              const withdrawTx = await pool.withdraw(amountWad);
              dispatchTransactionState({
                type: TransactionActionTypes.SetTransaction,
                payload: {
                  hash: withdrawTx.hash, description: 'Withdrawing',
                },
              });

              const withdrawReceipt = await withdrawTx.wait();
              dispatchTransactionState({
                type: TransactionActionTypes.DeleteTransaction,
                payload: {
                  hash: withdrawTx.hash,
                },
              });
              if (withdrawReceipt.status === 1) {
                return {
                  withdrawn: true,
                };
              }
            }
              break;
            case WithdrawalTypes.Yield: {
              const withdrawFundsTx = await pool.withdrawFundsAmount(amountWad);
              dispatchTransactionState({
                type: TransactionActionTypes.SetTransaction,
                payload: {
                  hash: withdrawFundsTx.hash, description: 'Withdrawing Funds',
                },
              });
              const withdrawFundsReceipt = await withdrawFundsTx.wait();
              dispatchTransactionState({
                type: TransactionActionTypes.DeleteTransaction,
                payload: {
                  hash: withdrawFundsTx.hash,
                },
              });
              if (withdrawFundsReceipt.status === 1) {
                return {
                  withdrawn: true,
                };
              }
            }
              break;
            default: { /* empty */ }
          }
        }

        // if (isFundsWithdraw) {
        //   const withdrawFundsTx = await pool.withdrawFundsAmount(amountWad);
        //   dispatchTransactionState({
        //     type: TransactionActionTypes.SetTransaction,
        //     payload: {
        //       hash: withdrawFundsTx.hash, description: 'Withdrawing Funds',
        //     },
        //   });
        //   const withdrawFundsReceipt = await withdrawFundsTx.wait();
        //   dispatchTransactionState({
        //     type: TransactionActionTypes.DeleteTransaction,
        //     payload: {
        //       hash: withdrawFundsTx.hash,
        //     },
        //   });
        //   if (withdrawFundsReceipt.status === 1) {
        //     return {
        //       withdrawn: true,
        //     };
        //   }
        // } else if (amountWad) {
        //   return {
        //     withdrawn: true,
        //   };
        // }
      }

      return {
        isPool: false,
        withdrawn: false,
      };
    } catch (error) {
      console.log('Withdraw error', error);
      return {
        withdrawn: false,
      };
    }
  };

  return {
    getPool,
    getAccountPool,
    deposit,
    withdraw,
    getAccountBalance,
    getRepaymentList,
  };
};
