import { useContext, useReducer } from 'react';
import axios from 'axios';
import { ListDataHandlerInterface } from './interfaces';
import { ListActionTypes, ListStateContext } from '../../context/states/ListState';
import { DataHandlerActionTypes } from '../interfaces';
import { dataStateReducer } from '../reducer';
import { requestInitialState } from '../initialDataState';
import { ListInterface, PoolInterface, ProjectInterface } from '../../context/states/ListState/interfaces';
import { BlockchainDataHandler } from '../BlockchainDataHandler';
import { httpRequest } from '../../../services';
import {
  LIST_API, POOL_LIST_API, PoolStatusTypes, PROJECT_LIST_API, RequestTypes,
} from '../../../constants';

export const ListDataHandler = (): ListDataHandlerInterface => {
  const [requestState] = useReducer(dataStateReducer, { ...requestInitialState });
  const [, setRequestState] = useReducer(dataStateReducer, { ...requestInitialState });
  const { listState, dispatchListState } = useContext(ListStateContext);
  const { getPool, getAccountPool } = BlockchainDataHandler();

  /**
   * Returns Home page data: TEAM, COMPANIES, TESTIMONIALS and SUCCESS STORIES.
   *
   * Will be called only in web version for now (21.01.23)
   */

  const getLists = async () => {
    const urls = Object.values(LIST_API);
    try {
      const requests = urls.map((url) => axios.get(url));
      let state: ListInterface = {};
      const responses = await axios.all(requests);
      await Promise.all(responses.map(async ({ config: { url }, data }) => {
        const urlPatterns = url?.split('/') || [];
        const urlSubPatterns = urlPatterns[urlPatterns.length - 1].split('-') || [];
        const listName = urlSubPatterns[urlSubPatterns.length - 1];
        state = { ...state, [listName]: data || [] };
      }));

      dispatchListState({
        type: ListActionTypes.SetLists,
        payload: state as ListInterface,
      });
    } catch (error: any) {
      setRequestState({
        type: DataHandlerActionTypes.SetError,
        payload: error.response?.data.detail || error.response?.data || error.message,
      });
    }
  };

  /**
   * Returns Helios app pools with blockchain data.
   *
   * Will be called only in app for now (21.01.23)
   */

  const getPoolList = async () => {
    try {
      const response = await httpRequest({
        url: POOL_LIST_API.GET_POOLS, method: RequestTypes.Get, withoutToken: true,
      });
      const pools: PoolInterface[] = response?.data || [];

      dispatchListState({
        type: ListActionTypes.SetLists,
        payload: { pools },
      });
      return pools;
    } catch (error: any) {
      setRequestState({
        type: DataHandlerActionTypes.SetError,
        payload: error.response?.data?.detail || error.response?.data || error.message,
      });
      return [];
    }
  };

  const getBlockchainAccountPoolList = async () => {
    try {
      let currentPools = listState.pools || [];
      if (getAccountPool) {
        const blockchainData = await Promise.all(
          currentPools.map((pool: PoolInterface) => getAccountPool(pool.id as string)),
        );
        currentPools = currentPools.map((pool: PoolInterface, index) => ({ ...pool, blockchainAccountData: blockchainData[index] })) as PoolInterface[];
      }
      dispatchListState({
        type: ListActionTypes.SetLists,
        payload: { pools: currentPools },
      });
    } catch (error: any) {
      setRequestState({
        type: DataHandlerActionTypes.SetError,
        payload: error.response?.data?.detail || error.response?.data || error.message,
      });
    }
  };

  const getBlockchainPoolList = async () => {
    try {
      let currentPools: PoolInterface[] = listState.pools || [];

      if (getPool) {
        const blockchainPools = currentPools.filter((pool) => pool.status !== PoolStatusTypes.Draft);
        const blockchainData = await Promise.all(
          blockchainPools.map((pool: PoolInterface) => getPool(pool.id as string)),
        );
        currentPools = blockchainPools.map((pool: PoolInterface, index) => ({ ...pool, blockchainData: blockchainData[index] }));
      }
      dispatchListState({
        type: ListActionTypes.SetLists,
        payload: { pools: currentPools },
      });
    } catch (error: any) {
      setRequestState({
        type: DataHandlerActionTypes.SetError,
        payload: error.response?.data?.detail || error.response?.data || error.message,
      });
    }
  };

  const getBlockchainPool = async (poolId: string) => { // this function need to call after deposit or withdraw actions
    try {
      const currentPools: PoolInterface[] = listState.pools || [];
      const currentPoolIndex: number = currentPools.findIndex((pool) => pool.id === poolId);

      if (currentPoolIndex !== -1) {
        let currentPool = currentPools[currentPoolIndex];
        if (getPool) {
          const blockchainData = await getPool(currentPool.id as string);
          if (currentPool.status !== PoolStatusTypes.Draft) {
            currentPool = { ...currentPool, blockchainData };
          }
        }
        if (getAccountPool) {
          const blockchainAccountData = await getAccountPool(currentPool.id as string);
          if (currentPool.status !== PoolStatusTypes.Draft) {
            currentPool = { ...currentPool, blockchainAccountData };
          }
        }
        currentPools[currentPoolIndex] = currentPool;
      }
      dispatchListState({
        type: ListActionTypes.SetLists,
        payload: { pools: currentPools },
      });
    } catch (error: any) {
      setRequestState({
        type: DataHandlerActionTypes.SetError,
        payload: error.response?.data?.detail || error.response?.data || error.message,
      });
    }
  };

  /**
   * Returns projects.
   *
   * Will be called only in app for now (21.01.23)
   */

  const getProjectList = async () => {
    try {
      const response = await httpRequest({
        url: PROJECT_LIST_API.GET_PROJECTS, method: RequestTypes.Get, withoutToken: true,
      });

      const projects: ProjectInterface[] = response?.data || [];
      dispatchListState({
        type: ListActionTypes.SetLists,
        payload: { projects },
      });
    } catch (error: any) {
      setRequestState({
        type: DataHandlerActionTypes.SetError,
        payload: error.response?.data?.detail || error.response?.data || error.message,
      });
    }
  };

  return {
    requestState,
    getLists,
    getPoolList,
    getBlockchainPoolList,
    getBlockchainAccountPoolList,
    getProjectList,
    getBlockchainPool,
  };
};
