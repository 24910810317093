import { HelElement, HelFC } from '../../interfaces';
import { AccountImpactInfoBoxProps } from './interfaces';
import { numberWithK } from '../../pages/helpers';

export const AccountImpactInfoBox:HelFC<AccountImpactInfoBoxProps> = ({ items, className = '' }): HelElement => (
  <div className={`account-impact-info-box ${className}`}>
    {items.map(({ value, title }, index) => (
      <div className="account-impact" key={`account-impact-info-${index}`}>
        <span className="value">{numberWithK(value)}</span>
        <span className="title">{title}</span>
      </div>
    ))}
  </div>
);
