import {
  createContext, useMemo, useReducer,
} from 'react';

import {
  ContextProviderPropsInterface,
  UseReducerType,
} from '../../../interfaces';
import {
  ConfigStateContextInterface,
} from './interfaces';

import {
  appConfigStateReducer,
  AppConfigStateInterface,
  appConfigInitialState,
  AppConfigStateActionType,
} from './AppConfigState';
import {
  appLoaderStateReducer,
  AppLoaderStateInterface,
  appLoaderInitialState,
  AppLoaderStateActionType,
} from './AppLoaderState';
import {
  appModalStateReducer,
  AppModalStateInterface,
  appModalInitialState,
  AppModalStateActionType,
} from './AppModalState';
import { HelElement, HelFC } from '../../../../interfaces';

export const ConfigStateContext = createContext<ConfigStateContextInterface>(
  {} as ConfigStateContextInterface,
);

export const ConfigStateProvider
: HelFC<ContextProviderPropsInterface> = ({ children }): HelElement => {
  const [appLoader, dispatchAppLoader] = useReducer<
  UseReducerType<AppLoaderStateInterface, AppLoaderStateActionType>
  >(appLoaderStateReducer, appLoaderInitialState);

  const [appConfig, dispatchAppConfig] = useReducer<
  UseReducerType<AppConfigStateInterface, AppConfigStateActionType>
  >(appConfigStateReducer, appConfigInitialState);

  const [appModal, dispatchAppModal] = useReducer<
  UseReducerType<AppModalStateInterface, AppModalStateActionType>
  >(appModalStateReducer, appModalInitialState);

  const value = useMemo(() => (
    {
      appLoader,
      dispatchAppLoader,
      appModal,
      dispatchAppModal,
      appConfig,
      dispatchAppConfig,
    }), [appConfig, appLoader, appModal]);
  return (
    <ConfigStateContext.Provider
      value={value}
    >
      { children}
    </ConfigStateContext.Provider>
  );
};
