import { HelElement, HelFC } from '../../interfaces';

import facebookIcon from '../../assets/images/media/facebook.svg';
import twitterIcon from '../../assets/images/media/twitter.svg';
import emailIcon from '../../assets/images/media/email.svg';

export const MediaBox: HelFC = (): HelElement => (
  <div className="media-box">
    <h6>Share:</h6>
    <img src={facebookIcon} alt="facebook" />
    <img src={twitterIcon} alt="twitter" />
    <img src={emailIcon} alt="email" />
  </div>
);
