import { HelElement, HelFC } from '../../interfaces';
import { PoolDetailBlockProps } from './interface';
import { numberWithCommas } from '../../pages/helpers';

export const PoolDetailBlock:HelFC<PoolDetailBlockProps> = ({
  label, value, prefix, suffix,
}): HelElement => (
  <div className="pool-detail-block">
    <span className="label">{label}</span>
    <div className="value-content">
      {prefix && <span className="symbol">{prefix}</span>}
      {(value || value === 0) && <span className="value">{numberWithCommas(value)}</span>}
      {suffix && <span className="symbol">{suffix}</span>}
    </div>
  </div>
);
