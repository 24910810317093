import { createContext, useMemo, useReducer } from 'react';
import {
  WithdrawStateContextInterface,
  WithdrawActionType,
  WithdrawStateInterface,
} from './interfaces';
import { withdrawInitialState } from './initialState';
import { withdrawStateReducer } from './reducer';
import { ContextProviderPropsInterface, UseReducerType } from '../../../interfaces';
import { HelElement, HelFC } from '../../../../interfaces';

export const WithdrawStateContext = createContext<WithdrawStateContextInterface>(
  {} as WithdrawStateContextInterface,
);

export const WithdrawStateProvider
:HelFC<ContextProviderPropsInterface> = ({ children }): HelElement => {
  const [
    withdrawState,
    dispatchWithdrawState,
  ] = useReducer<
  UseReducerType<WithdrawStateInterface, WithdrawActionType>
  >(withdrawStateReducer, withdrawInitialState);

  const value = useMemo(() => ({
    withdrawState,
    dispatchWithdrawState,
  }), [withdrawState]);

  return (
    <WithdrawStateContext.Provider
      value={value}
    >
      {children}
    </WithdrawStateContext.Provider>
  );
};
