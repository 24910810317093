import { HelElement, HelFC } from '../../interfaces';
import { StoryBoxProps } from './interfaces';
import { StoryCard } from '../cards';
import { useMediaQuery } from '../../utils';

export const StoryCardBox: HelFC<StoryBoxProps> = ({ items = [] }): HelElement => {
  const isTablet = useMediaQuery('(max-width: 860px)');

  return (
    <div className={isTablet ? 'cards-slide' : 'story-cards-box'}>
      <div className="card-container">
        {
          items?.map(
            (item, index) => (index < 4 ? (
              <StoryCard
                key={`story-card-${index}`}
                className={`${(!isTablet && (index === 0)) ? 'active' : ''} a${index}`}
                item={item || {}}
                index={0}
              />
            ) : null),
          )
        }
      </div>
    </div>
  );
};
