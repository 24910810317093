import { useContext, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import {
  HeliosPools,
  HeliosPool,
} from '../../../pages';
import { HelFC } from '../../../interfaces';
import { AppLayout } from '../../layouts';
import {
  AppConfigActionTypes, AppLoaderActionTypes, ConfigStateContext, // ListStateContext,
} from '../../../store';
import { ListDataHandler } from '../../../store/DataHandlers/ListDataHandler';

export const HeliosAppRoutes:HelFC = () => {
  const {
    appConfig: {
      isReady,
      drawWrapper,
    },
    dispatchAppConfig,
    dispatchAppLoader,
  } = useContext(ConfigStateContext);

  const { getBlockchainAccountPoolList } = ListDataHandler();

  useEffect(() => {
    if (isReady) {
      getBlockchainAccountPoolList().then(() => {
        dispatchAppConfig({ type: AppConfigActionTypes.AppConfigSetDrawWrapper, payload: true });
        dispatchAppLoader({ type: AppLoaderActionTypes.Close });
      });
    }
  }, [isReady]);

  return (
    <>
      {
    drawWrapper
      && (
      <Routes>
        <Route
          path="/pools"
          element={(
            <AppLayout className="helios-app-page">
              <HeliosPools />
            </AppLayout>
      )}
        />
        <Route
          path="/pools/:id"
          element={(
            <AppLayout className="pool-page">
              <HeliosPool />
            </AppLayout>
      )}
        />
        <Route path="*" element={<Navigate to="/pools" />} />
      </Routes>
      )
}
    </>
  );
};
