import { HelElement, HelFC } from '../../interfaces';
import { PoolCard } from '../cards';
import { PoolStatusTypes } from '../../constants';
import { PoolCardBoxProps } from './interfaces';
import { PoolLink } from '../../components/PoolLink';
import { PoolDetailProps } from '../cards/interfaces';

export const PoolCardBox: HelFC<PoolCardBoxProps> = ({ pools }): HelElement => (
  <div className="pool-cards-box">
    {pools.length ? pools.map(({
      status,
      name,
      images,
      overview,
      id,
      blockchainData = {},
    }, index) => {
      const {
        lockupPeriod = 0,
        poolSize,
        minInvestmentSize,
        symbol,
        apy,
        duration,
        totalDeposited,
      } = blockchainData;

      const poolDetailList: PoolDetailProps[] = [
        {
          label: 'Pool size:',
          value: poolSize || 0,
          currentValue: totalDeposited || 0,
          suffix: symbol,
        },
        {
          label: 'Minimum deposit',
          value: minInvestmentSize || 0,
          suffix: symbol,
        },
        {
          label: 'Hold period',
          value: lockupPeriod,
          suffix: lockupPeriod > 0 ? 'days' : 'day',
        },
        {
          label: 'APY',
          value: apy || 0,
          suffix: '%',
        },
        {
          label: 'Duration',
          value: duration || 0,
          suffix: 'y',
        },
      ];

      return (
        <div className="card" key={`pool-card-${index}`}>
          <PoolLink id={id as string}>
            <PoolCard
              title={name}
              image={images?.length ? images[0].urls.original : ''}
              description={overview}
              details={poolDetailList}
              closed={status === PoolStatusTypes.Closed}
              id={id}
            />
          </PoolLink>
        </div>
      );
    }) : (
      <div className="no-pool">
        There are no pools
      </div>
    )}
  </div>
);
