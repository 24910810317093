import { useContext, useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Header } from '../Header';
import { HelElement, HelFC } from '../../interfaces';
import {
  AppModalActionTypes,
  ConfigStateContext,
  ListStateContext,
} from '../../store';
import { ListDataHandler } from '../../store/DataHandlers/ListDataHandler';
import { getNetwork } from '../../utils';
import { Footer } from '../Footer';
import headerGradient from '../../assets/images/gradients/Header.png';
import { AccountDataHandler } from '../../store/DataHandlers/AccountDataHandler';

export const AppLayout: HelFC = ({ children, className }): HelElement => {
  const { chainId = process.env.REACT_APP_CHAIN_ID!, account } = useWeb3React();
  const { getBlockchainAccountPoolList } = ListDataHandler();
  const { checkAddressIsIdentified } = AccountDataHandler();
  const { listState: { pools = [] } } = useContext(ListStateContext);
  const { dispatchAppModal } = useContext(ConfigStateContext);
  const investedPools = pools?.filter((pool) => !!pool.blockchainAccountData?.accountInvestmentSize) || [];

  const NET = getNetwork(process.env.REACT_APP_CHAIN_ID!);

  useEffect(() => {
    if (chainId.toString() !== process.env.REACT_APP_CHAIN_ID!) {
      dispatchAppModal({
        type: AppModalActionTypes.MessageModal,
        payload: {
          cancelButtonText: 'OK',
          title: 'Network error',
          description: `Please select ${NET} network.`,
        },
      });
    } else {
      getBlockchainAccountPoolList(pools).catch((error) => { console.log('Data getting Error:', error); });
      if (account) {
        checkAddressIsIdentified(account).catch((error) => { console.log('Identification check Error:', error); });
      }
    }
  }, [account, chainId]);

  return (
    <div className={`app-layout ${!account ? 'not-connected' : ''} ${className}`}>
      <img className="gradient" src={headerGradient} alt="" />
      <Header />
      <div className="app-content-container">
        { children }
      </div>
      {!investedPools.length && (
      <div className="footer">
        <Footer />
      </div>
      )}
    </div>
  );
};
