import { HelElement, HelFC } from '../../interfaces';
import { ProjectCardBoxProps } from './interfaces';
import { ProjectCard } from '../cards';

export const ProjectCardBox: HelFC<ProjectCardBoxProps> = ({ projects, poolName }): HelElement => (
  <div className="project-cards-box">
    {projects.map(({
      name, images, overview,
    }, index) => (
      <ProjectCard
        name={name}
        image={images?.length ? images[0].urls.original : ''}
        overview={overview}
        poolName={poolName}
        key={`project-card-${index}`}
      />
    ))}
  </div>
);
