// import Carousel from 'react-multi-carousel';
import { HelElement, HelFC } from '../../interfaces';
import { TestimonialCardBoxProps } from './interfaces';
import { TestimonialCard } from '../cards';

export const TestimonialCardBox: HelFC<TestimonialCardBoxProps> = ({ items }): HelElement => (
  <div className="testimonial-cards-box">
    <div className="slide-track">
      {items?.map((
        {
          fullName: { firstName, lastName },
          jobTitle,
          description,
          urls,
        },
        index,
      ) => <div className="card" key={`testimonial-card-${index}`}><TestimonialCard title={`${firstName} ${lastName}`} jobTitle={jobTitle} image={urls?.original || ''} description={description} /></div>)}
      {items?.map((
        {
          fullName: { firstName, lastName },
          jobTitle,
          description,
          urls,
        },
        index,
      ) => <div className="card" key={`testimonial-card-slide-${index}`}><TestimonialCard title={`${firstName} ${lastName}`} jobTitle={jobTitle} image={urls?.original || ''} description={description} /></div>)}
      {/* {items.map(({ title, image, description }) => <TestimonialCard title={title} image={image} description={description} />)} */}
    </div>
  </div>
);
