import { HelElement, HelFC } from '../../interfaces';
import { IconPropsInterface } from './interfaces';

export const IconArrowLeft: HelFC<IconPropsInterface> = ({
  width, height, className, onClick,
}): HelElement => (
  <svg
    className={`icon-svg ${className || 'icon-primary'}`}
    id="svg-icon-arrow"
    onClick={onClick}
    width={width}
    height={height}
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="32" height="32" rx="16" fill="#EBECF1" />
    <rect x="32" y="32" width="32" height="32" rx="16" transform="rotate(180 32 32)" fill="#EBECF1" />
    <path d="M17.724 8.39071L10.1147 16L17.724 23.6094L19.6094 21.724L13.8854 16L19.6094 10.276L17.724 8.39071Z" fill="black" />
  </svg>
);

IconArrowLeft.defaultProps = {
  className: '',
  width: '2rem',
  height: '2rem',
};
