import { useContext, useEffect, useState } from 'react';
import Persona from 'persona';
import { useWeb3React } from '@web3-react/core';
import { HelElement, HelFC } from '../../interfaces';
import { ButtonBase, IconInfo, PoolBalanceProgressBlock } from '../../components';
import { PoolInfoBoxProps } from './interfaces';
import { CategoryTypes } from '../../constants';
import {
  AppModalActionTypes,
  WithdrawActionTypes,
  AccountStateContext,
  ConfigStateContext,
  WithdrawStateContext,
} from '../../store';
import { PoolInterface } from '../../store/context/states/ListState/interfaces';
import {
  PoolBlockchainAccountValues,
  PoolBlockchainValues,
} from '../../store/DataHandlers/BlockchainDataHandler/interfaces';
import { numberWithCommas } from '../../pages/helpers';
import { canWithdraw } from '../../utils/date';
import 'react-tooltip/dist/react-tooltip.css';
import { BlockchainDataHandler } from '../../store/DataHandlers/BlockchainDataHandler';
import { PoolInfoBlock } from '../../components/blocks/PoolInfoBlock';
import { AccountDataHandler } from '../../store/DataHandlers/AccountDataHandler';
import { WithdrawYield } from '../modals/contents';
import { DepositFunds } from '../modals/contents/DepositFunds';

export const PoolInfoBox: HelFC<PoolInfoBoxProps> = ({
  className,
  pool = {} as PoolInterface,
}): HelElement => {
  const { account } = useWeb3React();
  const { dispatchWithdrawState } = useContext(WithdrawStateContext);

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  const { dispatchAppModal } = useContext(ConfigStateContext);
  const { accountState: { isIdentified } } = useContext(AccountStateContext);

  const [currentPool, setCurrentPool] = useState(pool);
  const { getAccountPool } = BlockchainDataHandler();
  const { identifyAddress } = AccountDataHandler();

  const {
    // name = '',
    annualEmissionsAverted = 0,
    annualSolarEnergyProduced = 0,
    blockchainData = {} as PoolBlockchainValues,
    blockchainAccountData = {} as PoolBlockchainAccountValues,
  } = currentPool;
  const {
    lockupPeriod,
    duration = 0,
    apy = 0,
    poolSize = 0,
    totalDeposited = 0,
    minInvestmentSize = 0,
    symbol,
    availableToWithdraw = 0,
  } = blockchainData;
  const {
    accountInvestmentSize = 0,
    accountPoolBalance = 0,
    depositDate,
  } = blockchainAccountData;

  const yieldBalance = accountPoolBalance - accountInvestmentSize;
  const isCompleted = account && canWithdraw(depositDate as number, lockupPeriod);
  const availableWithdrawInvestment = accountInvestmentSize > availableToWithdraw ? availableToWithdraw : accountInvestmentSize;

  useEffect(() => {
    if (getAccountPool) {
      getAccountPool(pool.id as string).then((accountData) => {
        setCurrentPool({ ...pool, blockchainAccountData: accountData });
      }).catch((e) => {
        console.log(e);
      });
    }
  }, [account]);

  const openDepositModal = () => {
    dispatchAppModal({
      type: AppModalActionTypes.DepositModal,
      payload: {
        title: 'Deposit funds',
        contentTypes: [],
        data: pool,
        content: <DepositFunds />,
        disabled: true,
      },
    });
  };

  const openWithdrawModal = () => {
    dispatchWithdrawState({
      type: WithdrawActionTypes.WithdrawBalanceUpdate,
      payload: {
        yield: yieldBalance,
        investment: availableWithdrawInvestment,
      },
    });

    dispatchAppModal({
      type: AppModalActionTypes.DepositModal,
      payload: {
        title: 'Yield',
        contentTypes: [],
        data: pool,
        content: <WithdrawYield />,
        disabled: !yieldBalance,
      },
    });
  };

  const openPersonaModal = () => {
    dispatchAppModal({
      type: AppModalActionTypes.ModalInfoSet,
      payload: {
        disabled: true,
      },
    });
    const environmentId = process.env.REACT_APP_PERSONA_ENVIRONMENT_ID!;
    const client: any = new Persona.Client({
      templateId: process.env.REACT_APP_PERSONA_TEMPLATE_ID,
      environmentId,
      onReady: () => {
        dispatchAppModal({ type: AppModalActionTypes.Close });
        client.open();
      },
      onCancel: () => {
        dispatchAppModal({ type: AppModalActionTypes.Close });
        console.log('onCancel');
      },
      onError: (error) => {
        dispatchAppModal({ type: AppModalActionTypes.Close });
        console.log('error', error);
      },
      onEvent: (eventName, metadata = {}) => {
        console.log('eventName', eventName);
        console.log('metadata', metadata);
        if (eventName === 'load') {
          // Collect and save the inquiry ID for future use
          console.log('inquiryId', metadata.inquiryId);
        }
        if (eventName === 'start') {
          // Collect and save the inquiry ID for future use
          console.log('inquiryId', metadata.inquiryId);
        }
      },
      onComplete: ({ inquiryId: inqId, status }) => {
        // Inquiry completed. Optionally tell your server about it.
        console.log('status', status);
        identifyAddress(account as string, inqId).then(() => {
          openDepositModal();
        });
        console.log(`Sending finished inquiry ${inqId} to backend`);
      },
    });
  };

  const openValidationInformModal = () => {
    dispatchAppModal({
      type: AppModalActionTypes.InformModal,
      payload: {
        content:
  <div className="inform-content">
    <span className="title">Dear valued customer,</span>
    <span>
      To ensure the security and compliance of our services,
      we kindly request that all customers undergo KYC verification.
    </span>
    <span>
      This process is a standard practice in the financial industry
      and is required by law to prevent fraudulent activities.
    </span>
    <span>
      We appreciate your cooperation and assure you that your
      personal information will be kept confidential and secure.
    </span>
  </div>,
        submitButtonText: 'OK',
        submitCallBack: openPersonaModal,
        cancelButtonText: 'Cancel',
      },
    });
  };

  const openModal = isIdentified ? openDepositModal : openValidationInformModal;
  const openMetamaskModal = () => {
    dispatchAppModal({
      type: AppModalActionTypes.MetamaskModal,
      payload: {
        title: 'Please Connect Wallet',
        cancelButtonText: 'Cancel',
      },
    });
  };

  const mainInfo = (
    <div className="main-info">
      <div className="item">
        <span className="label">30-Day APY</span>
        <span className="value">
          <h4>{`${apy || 0}%`}</h4>
        </span>
      </div>
      <div className="divider" />
      <div className="item">
        <span className="label">Emissions Averted</span>
        <span className="value">
          <h4>
            {`${numberWithCommas(annualEmissionsAverted) || 0} kg`}
          </h4>
        </span>
      </div>
    </div>
  );
  console.log('accountInvestmentSize', accountInvestmentSize);

  return (
    <div className={`pool-info-box ${className || ''}`}>
      <div className="pool-main-info">
        {!(accountPoolBalance)
          ? (
            <PoolBalanceProgressBlock
              value={poolSize as number}
              currentValue={totalDeposited}
              suffix={symbol}
            />
          )
          : (
            <>
              <div className="balance">
                <span className="label">Balance</span>
                <span className="value">
                  <h2>{numberWithCommas(accountPoolBalance)}</h2>
                  <span className="symbol">
                    {symbol}
                  </span>
                  <div
                    onFocus={() => setTooltipIsOpen(true)}
                    onMouseOver={() => setTooltipIsOpen(true)}
                    onMouseLeave={() => setTooltipIsOpen(false)}
                    className="info-tooltip"
                  >
                    <IconInfo />
                    {tooltipIsOpen
                    && (
                      <div className="balance-tooltip">
                        <div className="tooltip-item">
                          <p className="tooltip-item-label">Deposit:</p>
                          <span className="tooltip-item-value">
                            <h4>{numberWithCommas(accountInvestmentSize)}</h4>
                            <span>
                              {symbol}
                            </span>
                          </span>
                        </div>
                        <div className="tooltip-item">
                          <p className="tooltip-item-label">Yield:</p>
                          <span className="tooltip-item-value">
                            <h4>
                              {numberWithCommas(yieldBalance)}
                            </h4>
                            <span className="tooltip-item-symbol">
                              {symbol}
                            </span>
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </span>
              </div>
            </>
          )}
        {
          (accountPoolBalance) ? (
            <ButtonBase
              className="button-without-icon"
              label="Withdraw"
              category={CategoryTypes.Filled}
              onClick={openWithdrawModal}
            />
          ) : (
            <ButtonBase
              className="button-without-icon"
              label="Invest Today"
              category={CategoryTypes.Filled}
              onClick={account ? openModal : openMetamaskModal}
            />
          )
        }
        {mainInfo}
      </div>
      <div className="pool-info">
        {accountInvestmentSize && isCompleted
          ? <PoolInfoBlock label="Hold Period" suffix="Completed" />
          : <PoolInfoBlock label="Hold Period" value={lockupPeriod} suffix="day" />}
        <PoolInfoBlock label="Minimum Investment Amount" value={minInvestmentSize} suffix={symbol} />
        <PoolInfoBlock label="Pool Duration" value={duration} suffix="years" />
        <PoolInfoBlock label="Energy Produced" value={annualSolarEnergyProduced} suffix="kWh" />
      </div>
      {!!(accountInvestmentSize || yieldBalance) && (
        <div className="reinvest">
          <PoolBalanceProgressBlock
            value={poolSize as number}
            currentValue={totalDeposited}
            suffix={symbol}
          />
          <ButtonBase
            className="button-without-icon"
            label="Reinvest"
            category={CategoryTypes.Outlined}
            onClick={openDepositModal}
          />
        </div>
      )}
    </div>
  );
};
