import { ethers } from 'ethers';
import { ListActionTypes } from './actions';
import {
  ListNameTypes, PoolStatusTypes, ProjectStatusTypes, RegionTypes,
} from '../../../../constants';

export interface ListRequestInterface {
  url: string,
  listName: ListNameTypes
}

interface ImageUrlsInterface {
  [key:string]: string
}

export interface ImageResponse {
  id:string,
  path:string,
  nullable: true,
  isPrimary: boolean,
  urls: ImageUrlsInterface,
}

interface BaseListItemInterface {
  id?: string,
  description: string,
  urls?: ImageUrlsInterface
}

export interface ProjectInterface {
  id?: string,
  name: string,
  overview?: string,
  region: RegionTypes,
  status: ProjectStatusTypes,
  images?: ImageResponse[],
}

export enum PoolStatus {
  Error = 'Error',
  NotExist = 'NotExist',
  Initialized = 'Initialized',
  Finalized = 'Finalized',
  Terminated = 'Terminated',
}

export interface TransactionInterface {
  transaction: ethers.providers.TransactionResponse,
  data: ethers.utils.TransactionDescription
}

export interface BlockchainPoolInterface {
  poolId: string,
  poolAddress: string,
  poolSize: number,
  apy: number,
  minInvestmentSize: number,
  duration: number,
  lockupPeriod: number,
  totalDeposited?: number,
  availableToWithdraw?: number,
  canWithdraw?: boolean,
  poolStatus?: PoolStatus,
  borrower?: string,
  symbol?: string,
  repaymentHistory?: TransactionInterface[]
}

export interface BlockchainPoolAccountInterface {
  accountInvestmentSize?: number,
  accountPoolBalance?: number,
  depositDate?: number,
  // TODO: The other fields will be moved here too in future probably (17.03.2023).
}

export interface PoolInterface {
  id?: string,
  name: string,
  overview?: string,
  description?: string,
  descriptionTitle?: string,
  region:RegionTypes,
  annualEmissionsAverted?: number,
  annualSolarEnergyProduced?: number,
  projects?: ProjectInterface[],
  status?: PoolStatusTypes,
  images?: ImageResponse[],
  blockchainData?: BlockchainPoolInterface,
  blockchainAccountData?: BlockchainPoolAccountInterface,
}

export interface CompanyInterface extends BaseListItemInterface {
  name: string,
}

export interface TeamMemberInterface extends BaseListItemInterface {
  fullName: {
    firstName:string,
    lastName:string,
  }
  position: string,
}

export interface TestimonialInterface extends BaseListItemInterface {
  fullName: {
    firstName:string,
    lastName:string,
  },
  jobTitle?: string
}

export interface StoryInterface extends BaseListItemInterface {
  name: string,
}

export interface ListInterface {
  pools?: PoolInterface[],
  projects?: ProjectInterface[],
  companies?: CompanyInterface[],
  team?: TeamMemberInterface[],
  testimonials?: TestimonialInterface[],
  stories?: StoryInterface[]
}

export type ListActionType =
  | { type: ListActionTypes.GetListsLocal }
  | { type: ListActionTypes.SetLists, payload: ListInterface };

export interface ListStateContextInterface {
  listState: ListInterface,
  dispatchListState: (props:ListActionType) => void,
}
