import { ListInterface } from './interfaces';

export const listInitialState: ListInterface = {
  pools: [],
  projects: [],
  companies: [],
  team: [],
  testimonials: [],
  stories: [],
};
