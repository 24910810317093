import { TransactionInterface, TransactionsInterface } from './interfaces';
import { LocalConfig } from '../../../../services';
import { ConfigItemNameTypes } from '../../../../constants';
import { ItemType } from '../../../../interfaces/dataListInterfaces/DataParamsInterface';

export const setTransaction = (state: TransactionsInterface, payload: TransactionInterface) => {
  const newState = {
    transactions: [...state.transactions, payload],
  };
  LocalConfig.setItem(ConfigItemNameTypes.Transactions, newState as ItemType);

  return newState;
};

export const removeTransaction = (state: TransactionsInterface, hash: string)
: TransactionsInterface => {
  const transactions: TransactionInterface[] = [];
  for (let r = 0; r < state.transactions.length; r += 1) {
    if (state.transactions[r].hash !== hash) {
      transactions.push(state.transactions[r]);
    }
  }

  const newState = { transactions };
  LocalConfig.setItem(ConfigItemNameTypes.Transactions, newState as TransactionsInterface);

  return newState;
};
