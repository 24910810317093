import { HelElement, HelFC } from '../../interfaces';
import { IconPropsInterface } from './interfaces';

export const IconProjects: HelFC<IconPropsInterface> = ({ width, height, className }): HelElement => (
  <svg
    className={`${className || 'icon-primary'}`}
    id="svg-icon-projects"
    width={width}
    height={height}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M44.8384 47.4231L20.9677 35.6195C18.7427 34.5191 18.8026 31.5707 21.0685 30.5467L45.0781 19.6958C47.1302 18.7681 49.535 18.7681 51.587 19.6958L75.5966 30.5467C77.8639 31.5707 77.9224 34.5191 75.6974 35.6195L51.8267 47.4231C49.6453 48.5022 47.0199 48.5022 44.8371 47.4231H44.8384Z" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M30.8542 54.8916L21.069 59.3142C18.8018 60.3383 18.7432 63.2867 20.9682 64.387L44.8389 76.1906C47.0204 77.2697 49.6457 77.2697 51.8286 76.1906L75.6992 64.387C77.9243 63.2867 77.8644 60.3383 75.5985 59.3142L65.8133 54.8916" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M30.8556 40.5085L21.069 44.9312C18.8018 45.9552 18.7432 48.9036 20.9682 50.004L44.8389 61.8076C47.0204 62.8867 49.6457 62.8867 51.8286 61.8076L75.6992 50.004C77.9243 48.9036 77.8644 45.9552 75.5985 44.9312L65.8133 40.5085" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconProjects.defaultProps = {
  className: '',
  width: '96px',
  height: '96px',
};
