import { WithdrawStateInterface, WithdrawStateReducerInterface } from './interfaces';
import { withdrawInitialState } from './initialState';
import { WithdrawActionTypes } from './actions';
import { updateWithdrawSteps } from './helpers';

export const withdrawStateReducer: WithdrawStateReducerInterface = (
  state = { ...withdrawInitialState },
  action,
): WithdrawStateInterface => {
  switch (action.type) {
    case WithdrawActionTypes.WithdrawBalanceUpdate:
      return { ...state, ...action.payload };

    case WithdrawActionTypes.WithdrawStepSet:
      return updateWithdrawSteps(state, 'add', action.payload);

    case WithdrawActionTypes.WithdrawStepDelete:
      return updateWithdrawSteps(state, 'delete');

    case WithdrawActionTypes.WithdrawReset:
      return { ...withdrawInitialState };

    default:
      return state;
  }
};
