import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import {
  ArticleBox,
  MediaBox,
  PoolInfoBox,
  PoolNameBox,
  ProjectCardBox,
} from '../modules';
import { HelElement, HelFC } from '../interfaces';
import { ListStateContext } from '../store';
import { BlockchainPoolInterface, PoolInterface, ProjectInterface } from '../store/context/states/ListState/interfaces';
import {
  PoolStatusTypes,
} from '../constants';
import { useMediaQuery } from '../utils';
import { BlockchainDataHandler } from '../store/DataHandlers/BlockchainDataHandler';
import { ParsedTransactionInterface } from '../store/DataHandlers/BlockchainDataHandler/interfaces';
import { TransactionCardBox } from '../modules/boxes/TransactionCardBox';

export const HeliosPool: HelFC = (): HelElement => {
  const params = useParams();
  const { account } = useWeb3React();
  const { listState: { pools = [] } } = useContext(ListStateContext);
  const [txHistory, setTxHistory] = useState<ParsedTransactionInterface[]>();
  const [pool, setPool] = useState(pools.find(({ id }) => id === params.id) || {} as PoolInterface);
  const { getRepaymentList, getAccountPool } = BlockchainDataHandler();
  const isTablet = useMediaQuery('(max-width: 860px)');
  const {
    status = '' as PoolStatusTypes,
    images = [],
    overview = '',
    name = '',
    projects = [] as ProjectInterface[],
    blockchainData = {} as BlockchainPoolInterface,
  } = pool;

  useEffect(() => {
    const { poolAddress, borrower } = blockchainData as BlockchainPoolInterface;
    if (getRepaymentList && borrower) {
      getRepaymentList(poolAddress).then((history) => {
        setTxHistory(history);
      });
    }
  }, [pool]);

  useEffect(() => {
    if (getAccountPool) {
      getAccountPool(pool.id as string).then((blockchainAccountData) => {
        setPool({ ...pool, blockchainAccountData });
      }).catch((e) => { console.log(e); });
    }
  }, [account]);

  return (
    <>
      <div className="pool-image">
        {(status === PoolStatusTypes.Closed) && (
          <span className="closed-badge">
            Closed
          </span>
        )}
        {images.length ? (
          <>
            <div className="overlay" />
            <img src={images[0]?.urls?.original} alt="story 1" />
          </>
        )
          : <div className="skeleton" />}
      </div>

      <div className="app-content">
        <div className="left-section">
          {!isTablet
            && (
            <PoolNameBox name={name} overview={overview} />
            )}
          <MediaBox />
          <ArticleBox item={pool} />
          {!!projects.length && <ProjectCardBox projects={projects} poolName={name} />}
          {!!txHistory?.length && <TransactionCardBox title="Repayment History" items={txHistory} />}
        </div>
        <div className="right-section">
          {isTablet
          && (
          <PoolNameBox name={name} overview={overview} />
          )}
          <PoolInfoBox pool={pool} />
        </div>
      </div>

    </>
  );
};
