import { HelElement, HelFC } from '../../interfaces';
import { IconPropsInterface } from './interfaces';

export const IconEnergy: HelFC<IconPropsInterface> = ({ width, height, className }): HelElement => (
  <svg
    className={`${className || 'icon-primary'}`}
    id="svg-icon-energy"
    width={width}
    height={height}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M62.5782 61.5781C70.0772 54.0791 70.0772 41.9209 62.5782 34.4219C55.0792 26.9229 42.9209 26.9229 35.4219 34.4219C27.9229 41.9209 27.9229 54.0791 35.4219 61.5781C42.9209 69.0771 55.0792 69.0771 62.5782 61.5781Z" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M46.5833 61.6747C46.5259 61.6561 46.4699 61.6374 46.4125 61.6187C46.8515 57.8086 47.2919 53.9973 47.739 50.1218H38.3013C42.5197 44.7183 46.6367 39.4457 50.7523 34.1743C50.8017 34.1917 50.8511 34.2103 50.9005 34.2277C50.6883 37.935 50.4761 41.6436 50.2599 45.4323H59.7123C55.2577 50.9439 50.9218 56.31 46.5846 61.6747H46.5833Z" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M49.0005 15V23.1646" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M49.0005 72.8352V80.9998" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M32.5005 19.4211L36.5828 26.4914" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M61.418 69.5085L65.5003 76.5788" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20.4199 31.5L27.4902 35.5823" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M70.5078 60.4177L77.5781 64.5" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M16.0005 48H24.1651" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M73.8369 48H82.0015" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M20.4199 64.5L27.4902 60.4177" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M70.5078 35.5823L77.5781 31.5" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M32.5005 76.5788L36.5828 69.5085" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M61.418 26.4914L65.5003 19.4211" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconEnergy.defaultProps = {
  className: '',
  width: '96px',
  height: '96px',
};
