import { useContext } from 'react';

import { NumericFormat } from 'react-number-format';
import { HelElement, HelFC } from '../../../interfaces';
import {
  AppModalActionTypes, ConfigStateContext,
} from '../../../store';
import { ActionButtons } from '../shared/ActionButtons';
import {
  CategoryTypes,
} from '../../../constants';
import { numberWithCommas } from '../../../pages/helpers';
import { BlockchainPoolInterface, PoolInterface } from '../../../store/context/states/ListState/interfaces';
import { ContentTypes } from './interface';
import { FinalizeInvestment } from './FinalizeInvestment';
import { getLockupPeriodHint } from './helpers';

export const DepositFunds: HelFC = (): HelElement => {
  const { appModal, dispatchAppModal } = useContext(ConfigStateContext);
  const { contentTypes = [], data } = appModal;

  const {
    minInvestmentSize = 0,
    totalDeposited = 0,
    poolSize = 0,
    lockupPeriod,
    symbol,
  } = data?.blockchainData as BlockchainPoolInterface;

  const maxDepositAmount = (+poolSize) - totalDeposited;

  const validate = (value: number) => {
    const isInvalid = !(value <= maxDepositAmount && value >= minInvestmentSize);

    if (isInvalid) {
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: {
          info: {
            value: value as number,
          },

          error: isInvalid,
          errorMessage: `Deposit amount is out of allowed range, it should be more then ${numberWithCommas(+minInvestmentSize)} and less then ${numberWithCommas(+maxDepositAmount)}`,
          disabled: isInvalid,
        },
      });
    } else {
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: {
          info: {
            value: value as number,
          },
          error: !value,
          errorMessage: !value ? 'You need to enter the required amount of deposit' : '',
          disabled: !value,
        },
      });
    }
    return true;
  };

  const handleDeposit = () => {
    dispatchAppModal({
      type: AppModalActionTypes.ModalInfoSet,
      payload: {
        title: 'Finalize Deposit',
        contentTypes: [...contentTypes, ContentTypes.DepositFunds],
        content: <FinalizeInvestment pool={data as PoolInterface} value={appModal.info?.value as number} />,
      },
    });
  };

  return (
    <>
      <div className="modal-description">
        <p>
          {`Currently acceptable maximum deposit amount into ${appModal.data?.name} Pool`}
          {' '}

        </p>
        <h6>
          {numberWithCommas(+maxDepositAmount)}
          {' '}
          {symbol}
        </h6>
      </div>
      <div className="withdraw-yield modal-content">
        <div className="modal-input">
          <div className="input-label">How much would you like to deposit?</div>
          <NumericFormat
            className={`${appModal.error ? 'error' : ''} input`}
            defaultValue={appModal.info?.value || ''}
            isAllowed={(values) => {
              const { floatValue = 0 } = values;
              return validate(floatValue);
            }}
            suffix={` ${symbol}`}
            thousandSeparator
          />
          {/* <span className="currency">{symbol || ''}</span> */}
          {appModal.errorMessage && <p className="error-message">{appModal.errorMessage}</p>}
        </div>
        <ActionButtons
          items={[
            {
              label: 'Cancel',
            },
            {
              category: CategoryTypes.Filled,
              label: 'Deposit',
              callBack: handleDeposit,
              disabled: appModal.disabled,
            },
          ]}
        />
      </div>
      <p className="hint-message">{getLockupPeriodHint(lockupPeriod)}</p>
    </>
  );
};
