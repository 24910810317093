import { cloneDeep } from 'lodash';
import {
  ListActionType,
  ListInterface,
} from './interfaces';

import { ListActionTypes } from './actions';
import { UseReducerType } from '../../../interfaces';
import { getLocalLists } from './localStore';
import { LocalConfig } from '../../../../services';

const setLists = (state: ListInterface, list: ListInterface) => {
  const newState = cloneDeep(state);
  return { ...newState, ...list };
};

export const listStateReducer: UseReducerType<ListInterface, ListActionType> = (state, action)
:ListInterface => {
  switch (action.type) {
    case ListActionTypes.GetListsLocal:
      return {
        ...getLocalLists(),
      };
    case ListActionTypes.SetLists:
      LocalConfig.setLists(action.payload);
      return setLists(state, action.payload);

    default:
      return state;
  }
};
