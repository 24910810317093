export const addDays = (date: Date, days: number) => {
  const dateCopy = new Date(date);
  dateCopy.setDate(date.getDate() + days);
  return dateCopy;
};

export const compareDates = (date1: Date, date2: Date) => {
  const g1 = new Date(date1);
  const g2 = new Date(date2);
  return (g1.getTime() >= g2.getTime());
};

export const canWithdraw = (depositDate: number, lockupPeriod: number) => {
  const depositTime = depositDate ? new Date(depositDate * 1000) : new Date();
  const lockupDate = addDays(depositTime, lockupPeriod);
  const isCompleted = compareDates(new Date(), new Date(lockupDate));
  return isCompleted;
};
