import { useContext } from 'react';
import { cloneDeep } from 'lodash';
import { AppModalActionTypes, ConfigStateContext } from '../../store';
import { HelElement, HelFC } from '../../interfaces';
import closeIcon from '../../assets/images/close-icon.svg';
import arrowBackIcon from '../../assets/images/arrow-back.svg';
import { ModalBaseProps } from './interfaces';
import { ContentTypesObject } from './contents/contentTypes';

export const ModalBase: HelFC<ModalBaseProps> = ({ className, children }): HelElement => {
  const { appModal, dispatchAppModal } = useContext(ConfigStateContext);
  const {
    title,
    content,
    isClosable,
    contentTypes = [],
  } = appModal;

  const handleBack = () => {
    if (contentTypes.length) {
      const newContentTypes = cloneDeep(contentTypes);
      newContentTypes.pop();
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: { ...ContentTypesObject[contentTypes[contentTypes.length - 1]], contentTypes: newContentTypes },
      });
    }
  };

  return (
    <div className={`modal ${className}`}>
      <div className="modal-header">
        {!!contentTypes.length
        && (
          <button className="modal-back-icon" onClick={handleBack}>
            <img src={arrowBackIcon} alt="Back icon" />
          </button>
        )}

        <span className="space-element" />

        {isClosable
        && (
          <button className="modal-close-icon" onClick={() => dispatchAppModal({ type: AppModalActionTypes.Close })}>
            <img src={closeIcon} alt="Close icon" />
          </button>
        )}
      </div>
      {
        !!title
        && <h3>{title}</h3>
      }
      {content || children}
    </div>
  );
};
