import { useContext, useReducer } from 'react';
import { PlatformDataHandlerInterface } from './interfaces';
import { dataStateReducer } from '../reducer';
import { requestInitialState } from '../initialDataState';
import {
  PlatformActionTypes,
  PlatformStateContext,
} from '../../index';
import { httpRequest } from '../../../services';
import {
  PLATFORM_API, RequestTypes,
} from '../../../constants';
import { DataHandlerActionTypes } from '../interfaces';

export const PlatformDataHandler = ():PlatformDataHandlerInterface => {
  const [requestState, setRequestState] = useReducer(dataStateReducer, { ...requestInitialState });
  const { dispatchPlatformState } = useContext(PlatformStateContext);

  const getPlatform = async () => {
    try {
      const response = await httpRequest({
        url: PLATFORM_API.GET_PLATFORM, method: RequestTypes.Get, withoutToken: true,
      });
      dispatchPlatformState({
        type: PlatformActionTypes.SetPlatform,
        payload: response.data,
      });
    } catch (error: any) {
      setRequestState({
        type: DataHandlerActionTypes.SetError,
        payload: error.response?.data?.detail || error.response?.data || error.message,
      });
    }
  };

  return {
    requestState,
    getPlatform,
  };
};
