import { Link } from 'react-router-dom';
import { HelElement, HelFC } from '../interfaces';
import { getApp, getAppUrl } from '../utils';

export const PoolLink: HelFC<{ id:string }> = ({ children, id }): HelElement => {
  const { subdomain } = getApp();
  if (!(subdomain === 'app')) {
    const appUrl = getAppUrl();
    return (
      <a href={`${appUrl}/pools/${id}`} target="_blank" rel="noreferrer">
        {children}
      </a>
    );
  }
  return (
    <Link to={`/pools/${id}`}>
      {children}
    </Link>
  );
};
