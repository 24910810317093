import { DataHandlerActionTypes, RequestActionType, RequestStateInterface } from './interfaces';
import { RequestStatusTypes } from '../../constants';
import { requestInitialState } from './initialDataState';

export const dataStateReducer = (state: RequestStateInterface, action: RequestActionType):RequestStateInterface => {
  switch (action.type) {
    case DataHandlerActionTypes.SetLoading:
      return {
        ...state,
        status: RequestStatusTypes.Loading,
      };

    case DataHandlerActionTypes.SetSuccess:
      return <RequestStateInterface>{
        ...state,
        status: RequestStatusTypes.Success,
        successMessage: action.payload || null,
        isFirstRequest: false,
      };

    case DataHandlerActionTypes.SetError:
      return <RequestStateInterface>{
        ...state,
        status: RequestStatusTypes.Error,
        errorMessage: action.payload || null,
        isFirstRequest: false,
      };

    case DataHandlerActionTypes.SetData:
      return {
        ...state,
        responseData: action.payload,
      };

    case DataHandlerActionTypes.ResetState:
      return {
        ...state,
        ...requestInitialState,
      };

    default:
      return state;
  }
};
