import { useContext } from 'react';

import { NumericFormat } from 'react-number-format';
import { HelElement, HelFC } from '../../../interfaces';
import {
  AppModalActionTypes, ConfigStateContext, WithdrawActionTypes, WithdrawStateContext,
} from '../../../store';
import { ContentTypes } from './interface';
import { ActionButtons } from '../shared/ActionButtons';
import {
  BlockchainPoolAccountInterface,
  BlockchainPoolInterface,
} from '../../../store/context/states/ListState/interfaces';
import { CategoryTypes, WithdrawalTypes } from '../../../constants';
import { WithdrawPermission } from './WithdrawPermission';
import { getErrorMessage } from './helpers';

export const WithdrawInvestment: HelFC = (): HelElement => {
  const { appModal, dispatchAppModal } = useContext(ConfigStateContext);
  const { withdrawState: { investment: investmentValue }, dispatchWithdrawState } = useContext(WithdrawStateContext);
  const { contentTypes = [], data } = appModal;
  const { accountInvestmentSize = 0 } = data?.blockchainAccountData as BlockchainPoolAccountInterface;
  const {
    availableToWithdraw = 0,
    symbol,
  } = data?.blockchainData as BlockchainPoolInterface;
  const availableWithdrawInvestment = accountInvestmentSize > availableToWithdraw ? availableToWithdraw : accountInvestmentSize;

  const validate = (value = 0) => {
    const isInvalid = !(value <= availableWithdrawInvestment);

    if (isInvalid) {
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: {
          error: isInvalid,
          errorMessage: getErrorMessage(availableWithdrawInvestment, symbol),
          disabled: isInvalid,
        },
      });
      dispatchWithdrawState({ type: WithdrawActionTypes.WithdrawBalanceUpdate, payload: { investment: value } });
    } else {
      dispatchAppModal({
        type: AppModalActionTypes.ModalInfoSet,
        payload: {
          error: value < 0,
          errorMessage: !(value >= 0) ? 'Enter amount of withdrawal' : '',
          disabled: !value,
        },
      });
      dispatchWithdrawState({ type: WithdrawActionTypes.WithdrawBalanceUpdate, payload: { investment: value } });
    }
    return true;
  };

  const handleWithdraw = () => {
    dispatchAppModal({
      type: AppModalActionTypes.ModalInfoSet,
      payload: {
        title: 'Confirm Withdrawal',
        contentTypes: [...contentTypes, ContentTypes.WithdrawInvestment],
        content: <WithdrawPermission type={WithdrawalTypes.Investment} />,
      },
    });
  };

  return (
    <>
      <div className="modal-description">
        <h6>
          Your investment:
          {' '}
          {accountInvestmentSize}
          {' '}
          {symbol}
        </h6>
        <p>
          Withdrawing your Principal investment means you will no longer earn yield or avert CO2 emissions. Are you sure you want to abandon the fight against climate change?
        </p>
      </div>
      <div className="withdraw-yield modal-content">
        <div className="yield-input-container">
          <div className="modal-input">
            <NumericFormat
              className={`${appModal.error ? 'error' : ''} input`}
              defaultValue={investmentValue}
              value={investmentValue}
              isAllowed={(values) => {
                const { floatValue = 0 } = values;
                return validate(floatValue);
              }}
              placeholder="Investment amount"
              suffix={` ${symbol}`}
              // disabled={!availableToWithdraw}
              thousandSeparator
            />
            {appModal.errorMessage && <p className="error-message">{appModal.errorMessage}</p>}
          </div>
        </div>
        <ActionButtons
          items={[
            {
              label: 'Cancel',
            },
            {
              category: CategoryTypes.Filled,
              label: 'Withdraw',
              callBack: handleWithdraw,
              disabled: appModal.disabled,
            },
          ]}
        />
      </div>
    </>
  );
};
