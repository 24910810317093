import { HelElement, HelFC } from '../../interfaces';
import { IconPropsInterface } from './interfaces';

export const IconBenefit: HelFC<IconPropsInterface> = ({ width, height, className }): HelElement => (
  <svg
    className={`${className || 'icon-primary'}`}
    id="svg-icon-benefit"
    width={width}
    height={height}
    viewBox="0 0 96 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M44.2757 22.2592C42.8694 21.9874 41.4179 21.844 39.9334 21.844C27.2964 21.844 17.0518 32.134 17.0518 44.8269C17.0518 51.823 20.165 58.0894 25.0745 62.3047" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M73.1548 55.837C75.6253 52.1679 77.0673 47.7429 77.0673 42.98C77.0673 30.2872 66.8226 19.9985 54.187 19.9985C47.0666 19.9985 40.7055 23.2662 36.5088 28.3892" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M66.1015 69.3311C76.0595 59.329 76.0595 43.1124 66.1015 33.1103C56.1435 23.1083 39.9983 23.1083 30.0403 33.1103C20.0823 43.1124 20.0823 59.329 30.0403 69.3311C39.9983 79.3331 56.1435 79.3331 66.1015 69.3311Z" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M55.3626 43.9771C54.8133 39.0625 50.6467 38.2252 46.4115 38.5769C43.0552 38.8555 40.6891 41.3218 40.7275 44.722C40.8072 51.6767 54.5523 50.6187 55.3626 57.4755C55.8254 61.3901 53.0458 63.8785 49.6786 63.9295C45.0533 63.9999 41.2782 63.4509 40.7275 58.5293" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M48.0698 33.6238V38.5067" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M48.0698 63.9351V68.818" stroke="#4F3EFF" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

IconBenefit.defaultProps = {
  className: '',
  width: '96px',
  height: '96px',
};
