import { RequestStateInterface } from './interfaces';
import { RequestStatusTypes } from '../../constants';

export const requestInitialState: RequestStateInterface = {
  status: RequestStatusTypes.Waiting,
  isFirstRequest: true,
  successMessage: '',
  responseData: {},
  responseError: '',
  errorMessage: '',
};
