import { useContext, useEffect } from 'react';
import {
  ImpactInfoBox,
  // MemberCardBox,
  StoryCardBox,
  TestimonialCardBox,
  PageTitleBox,
  WorkFlowCardBox,
  CompanyCardBox,
} from '../modules';
import earth from '../assets/videos/earth-new.mp4';

// import logoPark from '../assets/images/park.png';

import howItWorksGradient from '../assets/images/gradients/HowItWorks.png';
import testimonialsGradient from '../assets/images/gradients/OurMission.png';
import community from '../assets/images/gradients/Community.png';
import { HelElement, HelFC } from '../interfaces';
import {
  IconBenefit,
  IconEmission,
  IconEnergy,
  IconProjects,
} from '../components';
import {
  DirectionTypes,
} from '../constants';
import { PageLayout } from '../modules';
import { ListStateContext, PlatformStateContext } from '../store';
import { numberWithCommas } from './helpers';

export const Home: HelFC = (): HelElement => {
  const {
    listState: {
      testimonials,
      // team,
      stories,
      companies,
    },
  } = useContext(ListStateContext);

  const {
    platformState: {
      emissionsAverted = 0,
      returnsGenerated = 0,
      totalSolarFunded = 0,
      projectsFunded = 0,
    },
  } = useContext(PlatformStateContext);

  const impactInfoList = [{
    icon: <IconEmission />,
    value: `${numberWithCommas(emissionsAverted)} tons`,
    title: 'Emissions Averted',
  },
  {
    icon: <IconEnergy />,
    value: `$${numberWithCommas(totalSolarFunded)}`,
    title: 'Total Solar Funded',
  },
  {
    icon: <IconProjects />,
    value: `${projectsFunded}`,
    title: 'Projects Funded',
  },
  {
    icon: <IconBenefit />,
    value: `$${numberWithCommas(returnsGenerated)}`,
    title: 'Returns Generated',
  },

  ];

  useEffect(() => {
    const workFlowBg = document.getElementById('work-flow');
    const testimonialsBg = document.getElementById('testimonials');
    const communityBg = document.getElementById('community');

    window.addEventListener('scroll', () => {
      const value = window.scrollY;
      if (workFlowBg) {
        workFlowBg.style.top = `${(-1000 + value * 0.25)}px`;
      }
      if (testimonialsBg) {
        testimonialsBg.style.top = `${(-1300 + value * 0.2)}px`;
      }
      if (communityBg) {
        communityBg.style.top = `${(-1600 + value * 0.2)}px`;
      }
    });
  }, []);

  return (
    <PageLayout className="home-page">
      <PageTitleBox
        video={earth}
        title="The Evolution of Solar, Powered by Crypto"
        description="We’re building the future of impact investing, enabling anyone anywhere to invest directly in profitable, high impact solar projects."
        withActions
      />
      <div className="sub-content impact-info">
        <div className="title-description">
          <h2>Make a Difference with DEFI</h2>
        </div>
        <ImpactInfoBox
          direction={DirectionTypes.Horizontal}
          items={impactInfoList}
        />
      </div>
      <div className="sub-content work-flow">
        <img id="work-flow" className="gradient" src={howItWorksGradient} alt="" data-modifier="100" />
        <div className="title-description" data-modifier="5">
          <h2>How it works</h2>
          <p className="description">
            We fund profitable solar projects around the world, averting millions of tonnes of CO2 emissions while earning market-leading yield. It&apos;s that simple.
          </p>
        </div>
        <WorkFlowCardBox />
      </div>

      <div className="success-stories">
        <div className="title-description">
          <h2>Impact Beyond Energy</h2>
        </div>
        {!!stories?.length && <StoryCardBox items={stories} />}
      </div>

      {/* <div className="companies"> */}
      {/*  <div className="title-description"> */}
      {/*    <h2>Companies that support us</h2> */}
      {/*  </div> */}
      {/*  <CompanyCardBox items={companies} /> */}
      {/* </div> */}

      <div className="companies">
        <div className="title-description">
          <h2>Companies that support us</h2>
        </div>
        <CompanyCardBox items={companies} />
      </div>

      <div className="testimonials">
        <img id="testimonials" className="gradient" src={testimonialsGradient} alt="" />
        <div className="title-description">
          <h2>Testimonials</h2>
        </div>
        <TestimonialCardBox items={testimonials} />
      </div>

      <div className="sub-content our-mission">
        <div className="title-description">
          <h2>Our Mission</h2>
          <p className="description">
            {'   Helios represents the future of impact investing. Built from the ground up on web3 rails, we’ve optimized for transparency, accessibility, and liquidity. Through Helios, anyone can start funding clean energy in minutes, track progress on real time dashboards, and earn stable yields safe from the volatility of the markets.'}
            <br />
            {'   No need to choose between impact and profit, you can have both! In fact, we believe that the most effective, scalable solutions to climate change must be profitable; it’s the only way we can hope to address the massive problem we’re facing. '}
            <b>We make saving our planet both profitable and accessible to anyone.</b>
          </p>
        </div>
      </div>

      {/* <div className="our-team"> */}
      {/*  <div className="title-description"> */}
      {/*    <h2>Our Team</h2> */}
      {/*  </div> */}
      {/*  <MemberCardBox items={team} /> */}
      {/* </div> */}

      <div className="community">
        <img id="community" className="gradient" src={community} alt="" />
        <h1>“Our governments have failed to curb climate change, so we built a tool to enable anyone anywhere to take action. Let’s show them how it’s done!”</h1>
        <span className="ceo">William Skinner - CEO</span>
      </div>
    </PageLayout>
  );
};
