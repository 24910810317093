import { createContext, useMemo, useReducer } from 'react';
import {
  AccountStateContextInterface,
  AccountActionType,
  AccountInterface,
} from './interfaces';
import { accountInitialState } from './initialState';
import { accountStateReducer } from './reducer';
import { ContextProviderPropsInterface, UseReducerType } from '../../../interfaces';
import { HelElement, HelFC } from '../../../../interfaces';

export const AccountStateContext = createContext<AccountStateContextInterface>(
  {} as AccountStateContextInterface,
);

export const AccountStateProvider
:HelFC<ContextProviderPropsInterface> = ({ children }): HelElement => {
  const [
    accountState,
    dispatchAccountState,
  ] = useReducer<
  UseReducerType<AccountInterface, AccountActionType>
  >(accountStateReducer, accountInitialState);

  const value = useMemo(() => ({
    accountState,
    dispatchAccountState,
  }), [accountState]);

  return (
    <AccountStateContext.Provider
      value={value}
    >
      {children}
    </AccountStateContext.Provider>
  );
};
