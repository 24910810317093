import {
  useCallback,
  useContext,
  useState,
} from 'react';
import { useWeb3React } from '@web3-react/core';
import { useNavigate } from 'react-router-dom';
import { HelElement, HelFC } from '../../../interfaces';
import { AppLoaderActionTypes, AppModalActionTypes, ConfigStateContext } from '../../../store';
import { CategoryTypes, ModalInfoTypes, WithdrawalTypes } from '../../../constants';
import { useCopyToClipboard } from '../../../services';
import copyIcon from '../../../assets/images/copy-icon.svg';
import ethLink from '../../../assets/images/eth-link.svg';
import { numberWithCommas } from '../../../pages/helpers';
import { BlockchainDataHandler } from '../../../store/DataHandlers/BlockchainDataHandler';
import { PoolInterface } from '../../../store/context/states/ListState/interfaces';
import { ListDataHandler } from '../../../store/DataHandlers/ListDataHandler';
import { ActionButtons } from '../shared/ActionButtons';
import { getLockupPeriodHint } from './helpers';

export const FinalizeInvestment:HelFC<{ pool: PoolInterface, value?: number, isReinvest?: boolean }> = ({ pool, value, isReinvest = false }): HelElement => {
  const { appModal, dispatchAppModal, dispatchAppLoader } = useContext(ConfigStateContext);
  const { getBlockchainPool } = ListDataHandler();
  const [copy] = useCopyToClipboard();
  const [copiedOpen, setCopiedOpen] = useState(false);
  const {
    active, account,
  } = useWeb3React();
  const navigate = useNavigate();

  const { deposit, withdraw } = BlockchainDataHandler();

  const handleDeposit = useCallback(() => {
    if (deposit && withdraw && value) {
      dispatchAppModal({ type: AppModalActionTypes.Close });
      if (isReinvest) {
        const poolIdTo = pool?.blockchainData?.poolId;
        const poolIdFrom = appModal.data?.blockchainData?.poolId;

        withdraw(poolIdTo || '', value as number, WithdrawalTypes.Yield).then(
          ({ withdrawn }) => {
            if (withdrawn) {
              deposit(poolIdFrom || '', value).then(
                ({ deposited }) => {
                  if (appModal.isOpen) {
                    dispatchAppModal({ type: AppModalActionTypes.Close });
                  }
                  if (deposited) {
                    dispatchAppLoader({
                      type: AppLoaderActionTypes.Open,
                      payload: {
                        description: 'Please wait, updating data...',
                      },
                    });
                    getBlockchainPool(pool?.blockchainData?.poolId as string).then(() => {
                      dispatchAppLoader({ type: AppLoaderActionTypes.Close });
                    }).catch((error) => {
                      dispatchAppLoader({ type: AppLoaderActionTypes.Close });
                      console.log('Data getting Error:', error);
                    });
                    dispatchAppModal({
                      type: AppModalActionTypes.InfoModal,
                      payload: {
                        cancelButtonText: 'Return to dashboard',
                        cancelCallBack: () => {
                          navigate('/pools');
                          dispatchAppModal({ type: AppModalActionTypes.Close });
                        },
                        info: {
                          type: ModalInfoTypes.Success,
                          value,
                          description: 'Thank you very much for your deposit, please click the button below to return to your dashboard',
                          label: 'Deposited Successfully',
                        },
                      },
                    });
                  } else {
                    dispatchAppModal({
                      type: AppModalActionTypes.InfoModal,
                      payload: {
                        cancelButtonText: 'Return to dashboard',
                        cancelCallBack: () => {
                          navigate('/pools');
                          dispatchAppModal({ type: AppModalActionTypes.Close });
                        },
                        info: {
                          type: ModalInfoTypes.Fail,
                          value,
                          description: 'Explanation of reasons of failure Lorem ipsum dolor sit amet, consectetur adipiscing consectetur.',
                          label: 'Deposit Failed',
                        },
                      },
                    });
                  }
                },
              );
            } else {
              dispatchAppModal({
                type: AppModalActionTypes.InfoModal,
                payload: {
                  cancelButtonText: 'Return to dashboard',
                  cancelCallBack: () => {
                    navigate('/pools');
                    dispatchAppModal({ type: AppModalActionTypes.Close });
                  },
                  info: {
                    type: ModalInfoTypes.Fail,
                    value: value as number || 0,
                    description: 'Explanation of reasons of failure Lorem ipsum dolor sit amet, consectetur adipiscing consectetur.',
                    label: 'Withdrawal Failed',
                  },
                },
              });
            }
          },
        );
      } else {
        deposit(pool?.blockchainData?.poolId || '', value).then(
          ({ deposited }) => {
            if (appModal.isOpen) {
              dispatchAppModal({ type: AppModalActionTypes.Close });
            }
            if (deposited) {
              dispatchAppLoader({
                type: AppLoaderActionTypes.Open,
                payload: {
                  description: 'Please wait, updating data...',
                },
              });
              getBlockchainPool(pool?.blockchainData?.poolId as string).then(() => {
                dispatchAppLoader({ type: AppLoaderActionTypes.Close });
              }).catch((error) => {
                dispatchAppLoader({ type: AppLoaderActionTypes.Close });
                console.log('Data getting Error:', error);
              });
              dispatchAppModal({
                type: AppModalActionTypes.InfoModal,
                payload: {
                  // cancelButtonText: 'Return to dashboard',
                  cancelCallBack: () => {
                    navigate('/pools');
                    dispatchAppModal({ type: AppModalActionTypes.Close });
                  },
                  info: {
                    type: ModalInfoTypes.Success,
                    value,
                    description: 'Thank you very much for your deposit, please click the button below to return to your dashboard',
                    label: 'Deposited Successfully',
                  },
                },
              });
            } else {
              dispatchAppModal({
                type: AppModalActionTypes.InfoModal,
                payload: {
                  cancelButtonText: 'Return to dashboard',
                  cancelCallBack: () => {
                    navigate('/pools');
                    dispatchAppModal({ type: AppModalActionTypes.Close });
                  },
                  info: {
                    type: ModalInfoTypes.Fail,
                    value,
                    description: 'Explanation of reasons of failure Lorem ipsum dolor sit amet, consectetur adipiscing consectetur.',
                    label: 'Deposit Failed',
                  },
                },
              });
            }
          },
        );
      }
    }
  }, [value]);

  let address = '';
  if (active) {
    address = `${pool?.blockchainData?.poolAddress.substr(0, 4)}********${pool?.blockchainData?.poolAddress.substr(-4)}`;
  }

  const handleCopyClick = async () => {
    await copy(account as string).then((result) => {
      if (result) {
        setCopiedOpen(true);
        setTimeout(() => setCopiedOpen(false), 1000);
      }
    });
  };

  return (
    <>
      <div className="modal-description">By granting permission, you are allowing the following contract to access your funds</div>
      <div className="modal-content withdraw-yield">
        <div className="contract">
          <div className="pool-name">
            {pool?.images?.length && <img src={pool?.images[0].urls.small || ''} alt="pool 1" />}
            <h4>{pool?.name}</h4>
          </div>
          <span className="address">{address}</span>
          <button onClick={handleCopyClick}><img src={copyIcon} alt="copy" /></button>
          {copiedOpen && <div className="copy-popup">copied!</div>}
          <a href={`${process.env.REACT_APP_EXPLORER_BASE_LINK}/${process.env.REACT_APP_EXPLORER_NET_PATH}/address/${pool?.blockchainData?.poolAddress}`} target="_blank" rel="noreferrer">
            <img src={ethLink} alt="" />
          </a>
        </div>
        <div className="permission-value">
          <div className="label">You are depositing</div>
          <div className="value">
            <span>
              {numberWithCommas(value as number || 0)}
            </span>
            {pool?.blockchainData?.symbol || ''}
          </div>
        </div>
        <ActionButtons
          items={[
            {
              label: 'Reject',
            },
            {
              category: CategoryTypes.Filled,
              label: 'Deposit',
              callBack: handleDeposit,
            },
          ]}
        />
      </div>
      <p className="hint-message">{getLockupPeriodHint(pool?.blockchainData?.lockupPeriod)}</p>
    </>
  );
};
