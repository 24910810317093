import { TransactionsInterface } from './interfaces';
import { transactionInitialState } from './initialState';

const localStateName = 'helios_transactions';

export const getLocalTransactions = (): TransactionsInterface => {
  const localTransactions = localStorage.getItem(localStateName) ? JSON.parse(
    localStorage.getItem(localStateName) || '',
  ) as TransactionsInterface : transactionInitialState;
  return { ...localTransactions };
};

export const setLocalTransaction = (
  state: TransactionsInterface,
): TransactionsInterface => {
  localStorage.setItem(localStateName, JSON.stringify(state));
  return { ...state };
};
