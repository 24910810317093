import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { HelElement, HelFC } from '../../interfaces';
import successIcon from '../../assets/images/success-icon.svg';
import failIcon from '../../assets/images/fail-icon.svg';
import { ModalBase } from './ModalBase';
import { CategoryTypes, ModalInfoTypes } from '../../constants';
import { InfoModalProps } from './interfaces';
import { ActionButtons } from './shared/ActionButtons';
import { AppModalActionTypes, ConfigStateContext } from '../../store';

export const InfoModal:HelFC<InfoModalProps> = ({
  className,
  value,
  type,
  label,
  description,
}): HelElement => {
  const infoIcon = type === ModalInfoTypes.Success ? successIcon : failIcon;
  const { dispatchAppModal } = useContext(ConfigStateContext);
  const navigate = useNavigate();

  return (
    <ModalBase className="modal-medium">
      <div>
        <img src={infoIcon} alt="info icon" />
        <div className={`status ${type} ${className}`}>
          <div className="value">
            <span>
              {value}
            </span>
            {process.env.REACT_APP_CHAIN_SYMBOL}
          </div>
          <div className="label">{label}</div>
        </div>
        <div className="description">{description}</div>
      </div>
      <ActionButtons
        items={[
          {
            category: CategoryTypes.Outlined,
            label: 'Return to Dashboard',
            callBack: () => {
              navigate('/pools');
              dispatchAppModal({ type: AppModalActionTypes.Close });
            },
          },
        ]}
      />
    </ModalBase>
  );
};
