import { HelElement, HelFC } from '../../interfaces';

import { AppHeader } from '../init/AppHeader';
import { DiscordCard } from '../cards';
import { Footer } from '../Footer';

export const PageLayout: HelFC = ({ children, className }): HelElement => (
  <div className={`page-layout ${className}`}>
    <AppHeader />
    <div className="page-content-container">
      <div className="page-content">
        {children}
        <div className="discord">
          <DiscordCard
            title="Join our DISCORD"
            description="We’ve built a community of solar experts, climate activists and experienced builders. Join today to learn how you can contribute in the fight against climate change!"
          />
        </div>
      </div>
    </div>
    <div className="footer">
      <Footer />
    </div>
  </div>
);
