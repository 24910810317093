import { HelElement, HelFC } from '../../interfaces';

import { ArticleBoxProps } from './interfaces';

export const ArticleBox: HelFC<ArticleBoxProps> = ({
  item: {
    descriptionTitle = '',
    description = '',
  },
  className,
}): HelElement => (
  <div className={`article-box ${className || ''}`}>
    <h3>
      {descriptionTitle || <div className="skeleton" />}
    </h3>
    <p className="description">
      {description || <div className="skeleton" />}
    </p>
    <p className="description">
      {description || <div className="skeleton" />}
    </p>
    <p className="description">
      {description || <div className="skeleton" />}
    </p>
  </div>

);
