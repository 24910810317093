import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { HelElement, HelFC } from '../../../interfaces';
import {
  AppLoaderActionTypes,
  AppModalActionTypes,
  ConfigStateContext,
  WithdrawStateContext,
} from '../../../store';
import { CategoryTypes, ModalInfoTypes, WithdrawalTypes } from '../../../constants';
import { numberWithCommas } from '../../../pages/helpers';
import { BlockchainDataHandler } from '../../../store/DataHandlers/BlockchainDataHandler';
import {
  BlockchainPoolAccountInterface,
  BlockchainPoolInterface,
} from '../../../store/context/states/ListState/interfaces';
import { ListDataHandler } from '../../../store/DataHandlers/ListDataHandler';
import { ActionButtons } from '../shared/ActionButtons';

export const WithdrawPermission:HelFC<{ type: WithdrawalTypes }> = ({ type }): HelElement => {
  const { appModal: { info = {}, data }, dispatchAppModal, dispatchAppLoader } = useContext(ConfigStateContext);
  const { withdrawState } = useContext(WithdrawStateContext);

  const navigate = useNavigate();
  const { withdraw } = BlockchainDataHandler();
  const { getBlockchainPool } = ListDataHandler();
  const { poolId } = data?.blockchainData as BlockchainPoolInterface;
  const { accountPoolBalance = 0, accountInvestmentSize = 0 } = data?.blockchainAccountData as BlockchainPoolAccountInterface;
  const yieldBalance = accountPoolBalance - accountInvestmentSize;

  const handleWithdraw = () => {
    if (withdraw) {
      dispatchAppModal({ type: AppModalActionTypes.Close });
      withdraw(poolId || '', withdrawState[type] as number, type).then(
        ({ withdrawn }) => {
          if (withdrawn) {
            dispatchAppLoader({
              type: AppLoaderActionTypes.Open,
              payload: {
                description: 'Please wait, updating data...',
              },
            });
            getBlockchainPool(poolId).then(() => {
              dispatchAppLoader({ type: AppLoaderActionTypes.Close });
            }).catch((error) => {
              dispatchAppLoader({ type: AppLoaderActionTypes.Close });
              console.log('Data getting Error:', error);
            });
            dispatchAppModal({
              type: AppModalActionTypes.InfoModal,
              payload: {
                cancelButtonText: 'Return to dashboard',
                cancelCallBack: () => {
                  navigate('/pools');
                  dispatchAppModal({ type: AppModalActionTypes.Close });
                },
                info: {
                  type: ModalInfoTypes.Success,
                  value: (withdrawState[type] as number || 0) + (info.checkboxValue ? yieldBalance : 0),
                  description: 'Thank you very much for your withdrawal, please click the button below to return to your dashboard',
                  label: 'Withdrawn Successfully',
                },
              },
            });
          } else {
            dispatchAppModal({
              type: AppModalActionTypes.InfoModal,
              payload: {
                cancelButtonText: 'Return to dashboard',
                cancelCallBack: () => {
                  navigate('/pools');
                  dispatchAppModal({ type: AppModalActionTypes.Close });
                },
                info: {
                  type: ModalInfoTypes.Fail,
                  value: (withdrawState[type] as number || 0) + (info.checkboxValue ? yieldBalance : 0),
                  description: 'Explanation of reasons of failure Lorem ipsum dolor sit amet, consectetur adipiscing consectetur.',
                  label: 'Withdrawal Failed',
                },
              },
            });
          }
        },
      );
    }
  };

  return (
    <div className="withdraw-yield modal-content">
      <div className="permission-value">
        <div className="label">You are withdrawing</div>
        <div className="value">
          <span>
            {numberWithCommas((withdrawState[`${type}`] as number || 0) + (info.checkboxValue ? yieldBalance : 0))}
          </span>
          {process.env.REACT_APP_CHAIN_SYMBOL}
        </div>
      </div>
      <ActionButtons
        items={[
          {
            category: CategoryTypes.Outlined,
            label: 'Cancel',
          },
          {
            category: CategoryTypes.Filled,
            label: 'Confirm',
            callBack: handleWithdraw,
          },
        ]}
      />
    </div>
  );
};
