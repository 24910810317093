import { appConfigInitialState } from './initialState';

import { AppConfigActionTypes } from './actions';
import { AppConfigStateInterface, AppConfigStateReducerInterface } from './interfaces';

export const appConfigStateReducer: AppConfigStateReducerInterface = (
  state = { ...appConfigInitialState },
  action,
): AppConfigStateInterface => {
  switch (action.type) {
    case AppConfigActionTypes.AppConfigSetIsReady:
      return {
        ...state,
        isReady: action.payload,
      };

    case AppConfigActionTypes.AppConfigSetDrawWrapper:
      return {
        ...state,
        drawWrapper: action.payload,
      };

    case AppConfigActionTypes.AppConfigSetPreloaded:
      return {
        ...state,
        isPreloaded: action.payload,
      };

    case AppConfigActionTypes.AppConfigSetAppPreloaded:
      return {
        ...state,
        isAppPreloaded: action.payload,
      };

    default:
      return state;
  }
};
