import { HelElement, HelFC } from '../../interfaces';
import { TransactionCardBoxProps } from './interfaces';
import { TransactionCard } from '../cards';

export const TransactionCardBox: HelFC<TransactionCardBoxProps> = ({ items, title }): HelElement => (
  <div className="transaction-cards-box">
    <h4>{title}</h4>
    <div className="transactions">
      {items?.map((item, index) => (
        <TransactionCard item={item} key={`transaction-card-${index}`} />
      ))}
    </div>
  </div>
);
