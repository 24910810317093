import { HelElement, HelFC } from '../../interfaces';
import { ImpactInfoBlockProps } from './interface';

export const ImpactInfoBlock:HelFC<ImpactInfoBlockProps> = ({ icon, value, title }): HelElement => (
  <div className="impact-info-block">
    {icon}
    <h3>{value}</h3>
    <span>{title}</span>
  </div>
);
