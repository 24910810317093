import { createContext, useMemo, useReducer } from 'react';
import {
  TransactionStateContextInterface,
  TransactionActionType,
  TransactionsInterface,
} from './interfaces';
import { transactionInitialState } from './initialState';
import { transactionStateReducer } from './reducer';
import { ContextProviderPropsInterface, UseReducerType } from '../../../interfaces';
import { HelElement, HelFC } from '../../../../interfaces';

export const TransactionStateContext = createContext<TransactionStateContextInterface>(
  {} as TransactionStateContextInterface,
);

export const TransactionStateProvider
:HelFC<ContextProviderPropsInterface> = ({ children }): HelElement => {
  const [
    transactionState,
    dispatchTransactionState,
  ] = useReducer<
  UseReducerType<TransactionsInterface, TransactionActionType>
  >(transactionStateReducer, transactionInitialState);

  const value = useMemo(() => ({
    transactionState,
    dispatchTransactionState,
  }), [transactionState]);

  return (
    <TransactionStateContext.Provider
      value={value}
    >
      {children}
    </TransactionStateContext.Provider>
  );
};
