import {
  TransactionActionType,
  TransactionsInterface,
} from './interfaces';

import { TransactionActionTypes } from './actions';
import { UseReducerType } from '../../../interfaces';
import { getLocalTransactions } from './localStore';
import { removeTransaction, setTransaction } from './helpers';

export const transactionStateReducer: UseReducerType<TransactionsInterface, TransactionActionType> = (state, action)
:TransactionsInterface => {
  switch (action.type) {
    case TransactionActionTypes.GetTransactionsLocal:
      return getLocalTransactions();

    case TransactionActionTypes.SetTransaction:
      return setTransaction(state, action.payload);

    case TransactionActionTypes.DeleteTransaction:
      return removeTransaction(state, action.payload.hash);

    default:
      return state;
  }
};
